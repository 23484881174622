<template>
  <ModalUtility v-model="visible" :fullScreen="'true'" :draggable="draggable">
    <template #header>
      <div
        class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
      >
        <div
          class="absolute top-50 right-50 px-4 flex flex-col items-start justify-center w-full"
        >
          <h1
            class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20 truncate w-[95%]"
            v-tooltip.bottom="{
              value: sheetName,
              showDelay: 500,
            }"
          >
            {{ sheetName || "Untitled" }}
          </h1>

          <!-- Use for teleportation don't remove this tag -->
          <p id="modal-subtitle-teleport" class="font-semibold"></p>
          <!-- end of teleportation -->
        </div>

        <div
          class="absolute right-[60px] top-[18px] flex justify-end items-center gap-[8px]"
          v-if="!isPreviewMode"
        >
          <AppButton
            type="submit"
            @click="handleSaveCriteria"
            :loading="isSaving"
          >
            <template #icon_left>
              <span
                v-html="icon.checkBlack"
                class="mr-2"
                v-if="!isSaving"
              ></span>
            </template>
            Save
          </AppButton>
        </div>

        <div
          class="absolute right-[60px] top-[18px] flex items-center gap-[8px]"
          v-if="isPreviewMode"
        >
          <AppButton
            type="default"
            @click="handleCriteriaAction('copy')"
            v-tooltip.top="{
              value: 'Make a Copy',
              showDelay: 500,
            }"
            :disabled="isCopying"
            :loading="isCopying"
          >
            <template #icon_left>
              <span v-html="icon.copyContent"></span>
            </template>
          </AppButton>
          <AppButton
            type="submit"
            @click="handleCriteriaAction('attach')"
            :loading="isSaving"
          >
            <template #icon_left>
              <span
                v-html="icon.checkBlack"
                class="mr-2"
                v-if="!isSaving"
              ></span>
            </template>
            Save
          </AppButton>
        </div>

        <div class="btn-close-wrap absolute top-[10px] right-[10px]">
          <button
            class="p-2 text-white rounded-full"
            @click="handleCriteriaSheetModal"
          >
            <img src="@/assets/close.svg" class="w-4" />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="bg-flohh-neutral-95 w-full">
        <CriteriaSheetComponent
          ref="CriteriaSheetComponentRef"
          :relative="'true'"
          @getTitle="handleGetTitle"
          @handleRefreshGrading="handleRefreshGrading"
          :criteriaSheetData="criteriaSheetData"
          :gradeSchemaUuid="gradeSchemaUuid"
          :gradeSchema="gradeSchema"
          :previewMode="isPreviewMode"
          :makeCopy="isCopy"
          :handleSaveSidebar="handleSaveSidebar"
          :assignmentUuid="assignmentUuid"
        />
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Vue, Component, Ref, Model, Prop, Watch } from "vue-facing-decorator";
import { icons } from "@/utils/icons";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import CriteriaSheetComponent from "@/components/PSPDFKit/CriteriaSheetComponent.vue";
import { StorageDataKey } from "@/enums/enums";
import { LAYOUT_DATA } from "@/components/PSPDFKit/template";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { IGradeSchema } from "@/models/CriteriaSheet";
import emitter from "@/config/emitter";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
interface ICriteriaSheetComponent {
  saveCriteriaSheet: (saveOnly: boolean) => void;
}

interface ICriteriaSheetData {
  uuid: string;
}

interface ICriteriaACtion {
  uuid: string;
  action: string;
}

interface CriteriaSheetList {
  uuid: string;
  name: string;
}

@Component({
  components: {
    CriteriaSheetComponent,
    ModalUtility,
    InputText,
    Button,
    AppButton,
  },
})
export default class CriteriaSheetTemplateModal extends Vue {
  private criteriaSheetService = new CriteriaSheetService();
  toast = useToast();
  eventBus = emitter;

  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Ref
  public CriteriaSheetComponentRef!: ICriteriaSheetComponent;
  draggable = false;
  icon = icons;

  @Prop({
    type: Function,
  })
  toggleModal!: (isSave: boolean) => void;

  @Prop({
    type: Object,
  })
  criteriaSheetData: ICriteriaSheetData = {
    uuid: "",
  };

  @Prop({
    type: Function,
  })
  handleSelectedCriteriaSheet!: (uuid: string, name: string) => void;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  preview!: boolean;

  @Prop({
    type: String,
    default: false,
  })
  assignmentUuid!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  isAttachment!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isMarkingScreen!: boolean;

  criteriaSheetTemplates = [];
  selectedTemplate: any;
  selectedTemplateUuid!: any;

  isLoading = true;

  showCriteria = false;
  sheetName = "Untitled";

  gradeSchemaUuid!: string;
  gradeSchema!: IGradeSchema;
  searchText: any = "";
  criteriaUuid = "";
  isSaving = false;
  isPreviewMode = true;
  isCopy = false;

  isCopying = false;
  isMarking = false;

  @Watch("isEdit")
  isEditWatcher(value: boolean) {
    this.isPreviewMode = value;
  }

  @Watch("preview")
  previewWatcher(value: boolean) {
    this.isPreviewMode = value;
  }

  mounted() {
    //
    console.log(this);
    if (this.preview === false) {
      this.isPreviewMode = this.preview;
    }

    console.log("this.assignmentUuid");
    console.log(this.assignmentUuid);

    // if (this.$store.state.assignment) {
    //   this.assignmentUuid = this.$store.state.assignment.uuid;
    // }

    localStorage.removeItem(StorageDataKey.CriteriaSheet);
    localStorage.removeItem(StorageDataKey.CriteriaSheetConfig);
    localStorage.removeItem(StorageDataKey.RedoLayout);
    localStorage.removeItem(StorageDataKey.DeletedLayout);

    if (this.criteriaSheetData.uuid) {
      // this.handleGetCriteria();
      this.selectedTemplateUuid = this.criteriaSheetData.uuid;
    }

    // this.eventBus.on("SET_CRITERIA_TEMPLATE_UUID", (data: any) => {
    //   if (data.action === "View") {
    //     this.handleSelectedCriteria(data.uuid);
    //   }
    // });

    // this.eventBus.on("TOGGLE_CRITERIA_SHEET", () => {
    //   this.actionType = "add";
    //   this.isPreviewMode = false;
    //   this.handleToggleCriteriaSheet();
    // });
  }

  async handleGetCriteria() {
    this.isLoading = true;
    try {
      const res = await this.criteriaSheetService.getCriteriaSheet(
        this.criteriaSheetData.uuid
      );
      this.isLoading = false;
      if (res.data.ok) {
        //
        console.log(res);
        this.selectedTemplate = res.data.data;
      }
    } catch (e) {
      //
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  async handleCriteriaAction(action: string) {
    if (action === "edit") {
      //
      this.isPreviewMode = false;
    } else if (action === "copy") {
      //
      // console.log(this.selectedTemplateUuid);
      // console.log(this.selectedTemplate);
      this.isCopying = true;
      const template = localStorage.getItem(StorageDataKey.CriteriaSheetConfig)
        ? JSON.parse(
            localStorage.getItem(StorageDataKey.CriteriaSheetConfig) || ""
          )
        : undefined;

      console.log("template");
      console.log(template);
      if (template) {
        let templateName = `Copy of ${template.name}`;

        const config = {
          ...template,
          name: templateName,
        };

        this.handleGetTitle(templateName);
        await localStorage.setItem(
          StorageDataKey.CriteriaSheetConfig,
          JSON.stringify(config)
        );
        console.log("ZXCXZC");
        this.isPreviewMode = false;
        this.isCopy = true;
        this.isCopying = false;
      }
    } else if (action === "attach") {
      //
      this.isSaving = true;
      const template = localStorage.getItem(StorageDataKey.CriteriaSheetConfig)
        ? JSON.parse(
            localStorage.getItem(StorageDataKey.CriteriaSheetConfig) || ""
          )
        : undefined;

      if (template) {
        if (this.handleSelectedCriteriaSheet) {
          this.handleSelectedCriteriaSheet(
            this.criteriaSheetData.uuid,
            template.name
          );
        }
      }

      if (this.isMarkingScreen) {
        this.isPreviewMode = false;
        this.isMarking = true;
      }
    }
  }

  async handleSaveCriteria() {
    if (this.CriteriaSheetComponentRef) {
      this.isSaving = true;
      this.isPreviewMode = false;
      // this.eventBus.emit("HANDLE_SAVE_CRITERIA");
      const res: any = await this.CriteriaSheetComponentRef.saveCriteriaSheet(
        true
      );
      this.isSaving = false;
      this.isCopying = false;
      if (res && res.data.ok) {
        this.criteriaSheetData.uuid = res.data.data.sheetDetails.uuid;
        if (this.handleSelectedCriteriaSheet) {
          this.handleSelectedCriteriaSheet(
            res.data.data.sheetDetails.uuid,
            res.data.data.sheetDetails.name
          );
        }
        this.toggleModal(true);
      } else {
        this.toggleModal(false);
      }
    }
  }

  handleSaveSidebar(res: any) {
    //
    this.isSaving = false;
    this.isCopying = false;
    if (res && res.data.ok) {
      this.criteriaSheetData.uuid = res.data.data.sheetDetails.uuid;
      if (this.handleSelectedCriteriaSheet) {
        this.handleSelectedCriteriaSheet(
          res.data.data.sheetDetails.uuid,
          res.data.data.sheetDetails.name
        );
      }
      this.toggleModal(true);
    } else {
      this.toggleModal(false);
    }
  }

  handleToggleCriteriaSheet() {
    this.showCriteria = !this.showCriteria;
    if (this.isCopy) {
      this.isCopy = false;
    }
  }

  handleSelectedCriteria(uuid: any) {
    this.selectedTemplateUuid = uuid;
    this.criteriaSheetData.uuid = uuid;
    this.isPreviewMode = true;
    this.handleToggleCriteriaSheet();
  }

  public async handleRefreshGrading(data: any) {
    if (this.handleSelectedCriteriaSheet && data) {
      this.handleSelectedCriteriaSheet(this.criteriaSheetData.uuid, data.name);
    }
    this.eventBus.emit("REFRESH_CRITERIA_SHEET_LIST");
  }

  async handleCriteriaSheetModal() {
    const csConfig = localStorage.getItem(StorageDataKey.CriteriaSheetConfig);
    this.criteriaSheetData.uuid = "";
    this.showCriteria = false;
    this.isCopy = false;
    this.selectedTemplate = null;
    this.sheetName = "Untitled";
    this.toggleModal(false);
  }

  handleGetTitle(sheetName: string) {
    this.sheetName = sheetName;
    console.log(this.sheetName);
  }

  showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }

  hasInvalidEmails(arr: string[]) {
    return arr.some((email) => !this.isValidEmail(email));
  }

  isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}
</script>

<style scoped lang="scss"></style>
