<script lang="ts">
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { Prop, Model, Vue, Component, Ref } from "vue-facing-decorator";
import ModalUtility from "./ModalUtility.vue";
import CriteriaSheetComponent from "../PSPDFKit/CriteriaSheetComponent.vue";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import { StorageDataKey } from "@/enums/enums";
import { IGradeSchema } from "@/models/CriteriaSheet";
import FormField from "@/components/Layout/Forms/FormField.vue";
import { LAYOUT_DATA } from "../PSPDFKit/template";
import { IAttachedResource } from "@/models/CriteriaSheet";
import { Icon, icons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import emitter from "@/config/emitter";
interface ICriteriaSheetData {
  uuid: string;
}
interface IOptions {
  label: string;
  value: string;
}
interface ICriteriaSheetComponent {
  saveCriteriaSheet: () => void;
}
@Component({
  components: {
    Button,
    InputText,
    ModalUtility,
    CriteriaSheetComponent,
    FormField,
    AppButton,
  },
})
export default class SelectComment extends Vue {
  private criteriaSheetService = new CriteriaSheetService();
  eventBus = emitter;
  isSelectGrading = false;
  draggable = false;
  sheetName = "Untitled";
  previewMode = true;

  @Ref
  public CriteriaSheetComponentRef!: ICriteriaSheetComponent;

  @Prop({
    type: Boolean,
    required: false,
  })
  readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  showDefaultSelected!: boolean;

  @Prop({
    type: String,
    default: "Select a grading",
  })
  selectPlaceholder!: string;

  @Prop({
    type: String,
    required: true,
  })
  assignmentUuid!: string;

  @Model({
    type: String,
    required: true,
  })
  value!: string;
  criteriaSheetvalue!: string;

  criteriaSheetData: ICriteriaSheetData = {
    uuid: "",
  };

  gradeSchemaUuid!: string;
  gradeSchema!: IGradeSchema;

  options: IOptions[] = [];
  optionLoading = true;

  isDisabled = false;

  criteriaSheetTemplates = [];
  selectedTemplate: any;
  selectedTemplateUuid!: string;
  icon: Icon = icons;
  isSaving = false;
  async mounted() {
    //
    await this.handleRefreshGrading();
  }

  handleEditMode() {
    //
    let templateName = `Copy of ${this.selectedTemplate.sheetDetails.sheetConfig.detail.name}`;

    const config = {
      ...this.selectedTemplate.sheetDetails.sheetConfig.detail,
      name: templateName,
    };

    this.handleGetTitle(templateName);

    localStorage.setItem(
      StorageDataKey.CriteriaSheetConfig,
      JSON.stringify(config)
    );
    this.previewMode = false;
  }

  async getCriteriaSheetTemplates() {
    this.optionLoading = true;
    const res = await this.criteriaSheetService.getCriteriaSheets();
    this.options = [];
    if (res.data.ok) {
      this.criteriaSheetTemplates = res.data.data;
      res.data.data.map((item: any) => {
        const obj: IOptions = {
          label: item.sheetDetails.name,
          value: item.sheetDetails.uuid,
        };

        this.options.push(obj);
      });

      this.optionLoading = false;
    }
  }

  async handleGetCriteriaData() {
    try {
      const res = await this.criteriaSheetService.getCriteriaSheetAssignment(
        this.assignmentUuid
      );
      if (res.data.ok) {
        const data = res.data.data;
        if (data.length) {
          this.criteriaSheetvalue = data[0].sheetDetails.name;
          this.criteriaSheetData.uuid = data[0].sheetDetails.uuid;
          this.value = data[0].sheetDetails.uuid;
          this.previewMode = false;
          if (this.criteriaSheetvalue) {
            this.isDisabled = true;
          }
          const gradeSchemaRes = await this.criteriaSheetService.getGradeSchema(
            data[0].sheetDetails.uuid
          );

          if (gradeSchemaRes.data.ok) {
            this.gradeSchemaUuid = gradeSchemaRes.data.data.uuid;
            this.gradeSchema = gradeSchemaRes.data.data;
          }

          const cellsData = data[0].cells;
          const criteriaSheetCells: any = [];
          cellsData.forEach((o: any) => {
            criteriaSheetCells.push(o.cellConfig.detail);
          });

          this.handleGetTitle(data[0].sheetDetails.name);
          localStorage.setItem(
            StorageDataKey.CriteriaSheet,
            JSON.stringify(criteriaSheetCells)
          );
          localStorage.setItem(
            StorageDataKey.CriteriaSheetConfig,
            JSON.stringify(data[0].sheetDetails.sheetConfig.detail)
          );
        } else {
          this.value = "";
          this.criteriaSheetvalue = "Select Grading";
          this.criteriaSheetData.uuid = "";
          this.selectedTemplate = "";
          this.selectedTemplateUuid = "";
          this.isSelectGrading = false;
          this.isDisabled = false;
        }
      }
    } catch (e) {
      //
    }
  }

  handleClickAdd() {
    this.eventBus.emit("EVENT_TRIGGER", "AM005");
    if (this.selectedTemplate) {
      this.handleCriteriaSheetTemplate();
    } else {
      this.isSelectGrading = true;
      this.previewMode = false;
    }
    // this.$router.push({ name: "CriteriaSheet" });
  }

  handleGetTitle(sheetName: string) {
    this.sheetName = sheetName;
  }

  async handleCriteriaSheetTemplate() {
    try {
      this.selectedTemplate = this.criteriaSheetTemplates.find(
        (o: any) => o.sheetDetails.uuid === this.selectedTemplateUuid
      );
      let cells: any = [];
      if (this.selectedTemplate) {
        if (this.selectedTemplate.cells.length) {
          this.selectedTemplate.cells.map((item: any) => {
            cells.push(item.cellConfig.detail);
          });
        }
        let templateName =
          this.selectedTemplate.sheetDetails.sheetConfig.detail.name;
        if (this.previewMode) {
          templateName =
            this.selectedTemplate.sheetDetails.sheetConfig.detail.name;

          this.handleGetTitle(templateName);
        }
        const config = {
          ...this.selectedTemplate.sheetDetails.sheetConfig.detail,
          name: templateName,
        };

        localStorage.setItem(
          StorageDataKey.CriteriaSheet,
          JSON.stringify(cells)
        );

        localStorage.setItem(
          StorageDataKey.CriteriaSheetConfig,
          JSON.stringify(config)
        );
      }

      this.isSelectGrading = true;
    } catch (e) {
      console.error(e);
    }
  }

  public async handleRefreshGrading() {
    await this.getCriteriaSheetTemplates();
    await this.handleGetCriteriaData();
  }

  async handleCriteriaSheetModal() {
    const csConfig = localStorage.getItem(StorageDataKey.CriteriaSheetConfig);
    if (this.CriteriaSheetComponentRef && csConfig) {
      //
      if (!this.previewMode) {
        await this.CriteriaSheetComponentRef?.saveCriteriaSheet();
      } else {
        this.value = "";
        this.criteriaSheetvalue = "Select Grading";
        this.criteriaSheetData.uuid = "";
        this.isSelectGrading = false;
        this.selectedTemplate = null;
        this.sheetName = "Untitled";
        this.value = "";
        this.previewMode = true;
      }
    } else {
      this.isSelectGrading = false;
    }
  }

  handlePreviewMode(status: boolean) {
    this.previewMode = status;
  }

  async handeSaveCriteria() {
    //
    if (this.CriteriaSheetComponentRef) {
      this.isSaving = true;
      await this.CriteriaSheetComponentRef.saveCriteriaSheet();
      this.isSaving = false;
    }
    // this.eventBus.emit("HANDLE_SAVE_CRITERIA");
  }
}
</script>
<template>
  <div class="flex flex-row gap-x-[8px] justify-center items-center">
    <FormField
      v-model="value"
      :options="options"
      :loading="optionLoading"
      option-value="value"
      option-label="label"
      :placeholder="criteriaSheetvalue"
      type="select"
      :disabled="isDisabled"
      @update:model-value="
        (newValue: any) => {
          if (newValue) {
            previewMode = true;
            selectedTemplateUuid = newValue;
            handleCriteriaSheetTemplate();
          }
        }
      "
    />
    <Button
      size="small"
      severity="secondary"
      :disabled="optionLoading"
      :icon="
        (criteriaSheetData.uuid || selectedTemplate) && !previewMode
          ? 'pi pi-pencil'
          : 'pi pi-plus'
      "
      @click="handleClickAdd"
      :pt="{
        root: { class: '!w-[48px] !h-[45px]' },
      }"
      v-tooltip.top="{
        value:
          criteriaSheetData.uuid || selectedTemplate
            ? 'Edit criteria sheet or rubric'
            : 'Add a criteria sheet or rubric',
        showDelay: 500,
      }"
    ></Button>
  </div>

  <ModalUtility
    v-model="isSelectGrading"
    :fullScreen="'true'"
    :draggable="draggable"
  >
    <template #header>
      <div
        class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
      >
        <div
          class="absolute top-50 right-50 px-4 flex flex-col items-start justify-center w-full"
        >
          <h1
            class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20 truncate w-[95%]"
            v-tooltip.bottom="{
              value: sheetName,
              showDelay: 500,
            }"
          >
            {{ sheetName || "Untitled" }}
          </h1>

          <!-- Use for teleportation don't remove this tag -->
          <p id="modal-subtitle-teleport" class="font-semibold"></p>
          <!-- end of teleportation -->
        </div>

        <div
          class="absolute right-[60px] top-[18px]"
          v-if="criteriaSheetData.uuid === ''"
        >
          <AppButton
            type="submit"
            @click="handeSaveCriteria"
            class="ml-2"
            :loading="isSaving"
          >
            <span v-html="icon.checkBlack" class="mr-2" v-if="!isSaving"></span>
            Save
          </AppButton>
        </div>

        <div class="absolute right-[60px] top-[18px]" v-if="previewMode">
          <AppButton type="default" @click="handleEditMode">
            Make a copy
            <span v-html="icon.copyContent"></span>
          </AppButton>
          <!-- <button
            @click="handleEditMode"
            v-if="previewMode"
            class="flex gap-2 items-center"
          >
            Make a copy
            <span v-html="icon.copyContent"></span>
          </button> -->
        </div>
        <div class="btn-close-wrap absolute top-[10px] right-[10px]">
          <button
            class="p-2 text-white rounded-full"
            @click="handleCriteriaSheetModal"
          >
            <img src="@/assets/close.svg" class="w-4" />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="bg-flohh-neutral-95 w-full">
        <CriteriaSheetComponent
          ref="CriteriaSheetComponentRef"
          v-if="isSelectGrading"
          :relative="'true'"
          @getTitle="handleGetTitle"
          @toggleCriteriaSheet="isSelectGrading = false"
          @handleRefreshGrading="handleRefreshGrading"
          :criteriaSheetData="criteriaSheetData"
          :gradeSchemaUuid="gradeSchemaUuid"
          :assignmentUuid="assignmentUuid"
          :gradeSchema="gradeSchema"
          :previewMode="previewMode"
        />
      </div>
    </template>
  </ModalUtility>
</template>
