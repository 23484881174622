import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white p-[1.5em]"
}
const _hoisted_2 = {
  key: 1,
  class: "p-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TeacherUpdates = _resolveComponent("TeacherUpdates")!
  const _component_StudentUpdates = _resolveComponent("StudentUpdates")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createBlock(_component_ModalUtility, {
    modelValue: _ctx.isUpdatesModalOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isUpdatesModalOpen) = $event)),
    title: "Updates"
  }, {
    content: _withCtx(() => [
      (_ctx.updatesItems && _ctx.updatesItems.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.role === 'teacher')
              ? (_openBlock(), _createBlock(_component_TeacherUpdates, {
                  key: 0,
                  updates: _ctx.updatesItems
                }, null, 8, ["updates"]))
              : (_ctx.role === 'student')
                ? (_openBlock(), _createBlock(_component_StudentUpdates, {
                    key: 1,
                    updates: _ctx.updatesItems
                  }, null, 8, ["updates"]))
                : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, "No updates are available"))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}