import UpdatePasswords from "@/components/User/Credentials/UpdatePasswords.vue";
import axios from "axios";

const AUTH_BASE_URL = "/authentication";
const REQUEST_RESET_PASSWORD = "/password/request-reset";
const RESET_PASSWORD = "/password/reset";
const REFRESH_TOKEN_URL = `${AUTH_BASE_URL}/refresh-token`;
const UPDATE_PASSWORD = "/users/update-password";
const SUPER_ADMIN_BASE_URL = "/super-admin/users";
export interface refreshToken {
  refreshToken: string;
}
export interface UpdatePassword {
  newPassword: string;
  newPasswordConfirmation: string;
  currentPassword: string;
}
export interface SuperAdminUpdatePassword {
  userUuid?: string;
  newPassword: string;
}
export interface AccountsPortalVerificationPayload {
  code: string;
  type: string;
  token: string;
}
export default class AuthenticationService {
  getAuthMe(params: refreshToken) {
    return axios.post(REFRESH_TOKEN_URL, params);
  }

  onRequestReset(payload: { email: string }) {
    // send email
    return axios.post(`${AUTH_BASE_URL}${REQUEST_RESET_PASSWORD}`, payload);
  }

  onResetPassword(payload: { token: string; password: string }) {
    //reset pass
    return axios.post(`${AUTH_BASE_URL}${RESET_PASSWORD}`, payload);
  }

  getIdentifcationToken() {
    return axios.get(`${AUTH_BASE_URL}/identification-token`);
  }

  onUpdatePassword(payload: UpdatePassword) {
    return axios.put(UPDATE_PASSWORD, payload);
  }

  superAdminUpdatePassword(payload: SuperAdminUpdatePassword) {
    return axios.put(
      `${SUPER_ADMIN_BASE_URL}/${payload.userUuid}/reset-password`,
      { newPassword: payload.newPassword }
    );
  }

  requestAccountsPortalOTP() {
    return axios.post("/accounts/request-access");
  }

  verifyAccountsPortalAccess(payload: AccountsPortalVerificationPayload) {
    return axios.post("/authentication/otp/verify", payload);
  }
}
