<template>
  <div class="" v-if="isAuthScreen">
    <AuthenticationView :view="authView">
      <router-view />
    </AuthenticationView>
  </div>
  <div class="" v-if="!isAuthScreen">
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
  <div
    v-if="environment !== 'production'"
    class="version-container"
    @click="redirectToVersionPage"
  >
    <p>{{ environment }} - {{ version }} - {{ releaseDate }} - {{ commit }}</p>
  </div>

  <Toast :base-z-index="10000" />
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-facing-decorator";
import NavigationComponent from "@/components/Navigation/NavigationComponent.vue";
import AuthenticationView from "@/views/Authentication/AuthenticationView.vue";
import { useRoute } from "vue-router";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Toast from "primevue/toast";
import AppLayout from "./components/Layout/AppLayout.vue";
import { AuthProfile, GetAuthUser, getAuthUser } from "./config/auth";
import axios, { AxiosResponse } from "axios";
import { useToast } from "primevue/usetoast";
import { version } from "@/utils/version";
import { useRecaptchaProvider } from "vue-recaptcha";
import emitter from "@/config/emitter";
import {
  useComposeTelemetry,
  useSendTelemetry,
  useUpdateEvents,
  useDeleteEvents,
  getEvents,
  getSessionId,
} from "@/utils/telemetry";
import { encryptString } from "@/utils/helper";
import { EventDetail } from "@/utils/telemetry/type";

const AUTH_SCREEN = [
  "/",
  "/login",
  "/register",
  // "/register/form",
  "/register/details",
  "/register/onboarding",
  "/teacher/invite",
  "/student/invite",
  "/student/registration",
  // "/student/activation",
  "/student/success",
  "/student/login",
  "/forgot-password",
  "/reset-password",
  "/superadmin/login",
  "/activation",
  "/student/shared-assignment",
];

interface IRoute {
  path: string;
}

@Component({
  components: {
    AuthenticationView,
    NavigationComponent,
    Button,
    Dialog,
    Toast,
    AppLayout,
  },
})
export default class App extends Vue {
  toast = useToast();
  eventBus = emitter;
  //
  isAuthScreen = true;
  authView = "";
  showSideNavigation = true;

  visible = false;

  token = "";
  environment = version.environment;
  version = version.number;
  releaseDate = version.releaseDate;
  commit = version.commit;

  intervalId!: any;
  isMaintenance = false;

  entities = [
    "InviteCodeEntity",
    // "GradeSchemaEntity",
    // "CriteriaSheetEntity",
    // "CommentBankEntity",
  ];

  // @Watch("$route", { immediate: true, deep: true })
  // onRouteChange(newRoute: IRoute) {
  //   this.isAuthScreen = AUTH_SCREEN.includes(newRoute.path);
  //   this.authView = newRoute.path;
  // }

  startInterval() {
    this.intervalId = setInterval(async function () {
      const sessionId = getSessionId();
      const events = getEvents(sessionId);
      if (events.length > 0) {
        await useSendTelemetry();
      }
    }, 300000); // 5 minutes to avoid spam, temporary
  }

  stopInterval() {
    clearInterval(this.intervalId);
  }

  restartInterval() {
    useDeleteEvents();
    this.stopInterval();
    this.startInterval();
  }

  created() {
    this.isMaintenance = process.env.VUE_APP_MAINTENANCE;
    if (process.env.VUE_APP_MAINTENANCE) {
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
    }
    useRecaptchaProvider();
    this.startInterval();
    axios.interceptors.request.use(
      async (config) => {
        const route = this.$route;
        if (route.name) {
          const method = config.method;

          if (
            (method === "get" || method === "post" || method === "delete") &&
            config.headers &&
            config.headers["x-flohh-telemetry"]
          ) {
            const telemetryData = useComposeTelemetry(route, config);

            const res: AxiosResponse = await axios.get(
              "/telemetry/encrypt-key"
            );
            if (res.data.ok) {
              const secret = res.data.data.encryptKey;
              const encrypted = encryptString(
                JSON.stringify(telemetryData),
                secret
              );
              config.headers["x-flohh-telemetry"] = btoa(encrypted);
              this.restartInterval();
              return config;
            }
          }
          if (method === "post" || method === "put") {
            const telemetryData = useComposeTelemetry(route, config);
            if (telemetryData) {
              config.data.telemetry = telemetryData;
            }
            config.headers.delete("tAction");
            this.restartInterval();
            return config;
          }
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          // if (error.response.status === 401 || error.response.status === 404) {
          // Redirect to login page
          if (localStorage.getItem("auth")) {
            localStorage.clear();
            window.location.href = "/";
          }
          const isLoginPage = this.$route.path.search("/login");
          this.showMessage(error.response.data.message, "error");
          if (
            this.$route.path.search("/superadmin") > -1 &&
            isLoginPage === -1
          ) {
            // window.location.href = "/superadmin/login";
          } else {
            if (
              this.$route.path.search("/student") > -1 &&
              isLoginPage === -1
            ) {
              window.location.href = "/student/login";
            } else if (this.$route.path.search("/app/version") > -1) {
              return;
            } else {
              if (isLoginPage === -1) {
                // window.location.href = "/login";
              }
            }
          }
        } else {
          // Show a generic error message
          if (this.entities.includes(error.response.data?.details?.entity)) {
            this.showMessage(
              "Could not find the template requested. It does not exist or has been deleted by the owner.",
              "error"
            );
          } else {
            if (error.response.data.devInfo.id === "ENTITY_DOES_NOT_EXIST") {
              this.showMessage(error.response.data.details.info, "error");
            } else {
              this.showMessage(error.response.data.message, "error");
            }
          }
        }
        return Promise.reject(error);
      }
    );

    this.eventBus.on("EVENT_TRIGGER", (eventId: string) => {
      const route = this.$route;
      useUpdateEvents(route, eventId);
    });

    this.eventBus.on("CUSTOM_EVENT_TRIGGER", (eventDetail: EventDetail) => {
      const route = this.$route;
      useUpdateEvents(route, eventDetail.id, eventDetail);
    });
  }

  handleCheckToken() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token.toString();
      this.$router.push({
        path: "/reset-password",
        query: { token: this.token },
      });
    }
  }

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    // Solution for tooltip showing on random spots as per sir Ian
    const tooltip = document.querySelector(".p-tooltip") as HTMLElement;
    if (tooltip) {
      tooltip.remove();
    }
  }

  mounted() {
    console.log("IS MAINTENANCE");
    console.log(process.env.VUE_APP_MAINTENANCE);
    if (process.env.VUE_APP_MAINTENANCE) {
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
    }

    this.version = version.number;
    const currentPage = this.getCurrentPage();
    const pathName = window.location.pathname;
    this.isAuthScreen = AUTH_SCREEN.includes(pathName);
    this.authView = currentPage;
    const user: GetAuthUser = getAuthUser();
    const authUser: AuthProfile = user.authUser;

    const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    const shareCode = urlParams.get("share-code");

    if (shareCode) {
      sessionStorage.setItem("shareCode", shareCode);
      if (pathName.search("comment-bank") > 0) {
        sessionStorage.setItem("sharePage", "/comment-bank");
      } else if (pathName.search("criteria-sheet-bank") > 0) {
        sessionStorage.setItem("sharePage", "/criteria-sheet-bank");
      }

      if (pathName.search("grade-schema-bank") > 0) {
        sessionStorage.setItem("sharePage", "/grade-schema-bank");
      }
    }

    if (!authUser.accessToken) {
      this.isAuthScreen = true;
      if (pathName.search("/superadmin") > -1) {
        if (!AUTH_SCREEN.includes(pathName)) {
          this.$router.push("/superadmin/login");
        }
      } else if (
        pathName.search("/student") > -1 ||
        pathName.includes("/classes")
      ) {
        if (!AUTH_SCREEN.includes(pathName)) {
          this.$router.push("/student/login");
        }
      } else if (pathName.search("/activation") > -1) {
        // this.$router.push("/superadmin/login");
      } else if (pathName.search("/reset-password") > -1) {
        this.handleCheckToken();
      } else if (pathName.search("/app/version") > -1) {
        return;
      } else if (
        pathName.search("/authenticate/google") > -1 ||
        pathName.search("/authenticate/microsoft") > -1
      ) {
        return;
      } else if (pathName.search("/teacher/invite") > -1) {
        return;
      } else {
        if (pathName.search("/login") > -1) {
          this.$router.push("/login");
        } else {
          // this.$router.push("/");
          if (!AUTH_SCREEN.includes(pathName)) {
            this.$router.push("/");
          }
        }
        // this.$router.push("/login");
      }
    } else {
      if (pathName.search("/register/onboarding") > -1) {
        this.isAuthScreen = true;
        localStorage.setItem("onboarding", "visible");
      } else {
        this.isAuthScreen = false;
        localStorage.setItem("onboarding", "hide");
        if (pathName === "/") {
          this.$router.push("/dashboard");
        }
      }
    }

    window.addEventListener("scroll", this.handleScroll);
  }

  redirectToVersionPage() {
    this.$router.push({
      name: "Versioning",
    });
  }

  getCurrentPage(): string {
    const route = useRoute();
    return route.path;
  }

  toggleSideNavigation() {
    this.showSideNavigation = !this.showSideNavigation;
  }

  isCurrentPath(path: string): boolean {
    return this.$route.path === path;
  }

  private showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style lang="scss">
.app-wrapper {
  display: flex;
  min-height: calc(100vh - 80px);
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 80px;
  position: relative;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #555555;
  }
}
.side-navigation {
  width: 140px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  overflow-y: auto;
  background-color: #f4cddc;
  transition: 0.3s ease;
  position: fixed;
  transform: translateX(-140px);
  top: 80px;
  left: 0;
  z-index: 3;
  &.active {
    transform: translateX(0);

    .btn-burger {
      opacity: 1;
    }
  }
  .top-wrap,
  .bottom-wrap {
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px;
    &:hover > a {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    a {
      display: inline-block;
      text-align: left;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 8px;
      transition: 0.3s ease;
      pointer-events: auto;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .bottom-wrap {
    position: relative;
    &:before {
      content: "";
      width: calc(100% - 30px);
      height: 1px;
      background-color: #555;
      display: block;
      position: absolute;
      top: 0;
      opacity: 0.5;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
}

.route-wrapper {
  width: 100vw;
  transition: 0.3s ease;
  position: relative;
  left: 0;
  &.active {
    left: 140px;
    width: calc(100vw - 140px);

    .wrapper.library-hide .pdf-wrap {
      width: calc(100vw - 180px) !important;
    }
  }

  .wrapper.library-hide .pdf-wrap {
    width: calc(100vw - 40px) !important;
  }
}
.version-container {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 10px;
  cursor: pointer;
  p {
    font-size: 10px;
  }
}
</style>
@/utils/telemetry
