<template>
  <ModalUtility title="Edit Student Details" v-model="modalOpen">
    <template #content>
      <div v-if="showResult" class="w-full px-4 py-7">
        <div v-if="successStudents.length > 0" class="mb-6">
          <AppTypographyText
            label="Student updates success"
            variant="bd"
            type="subtitle"
            class="mb-2"
          />
          <AppTypographyText
            :label="`${successStudents.length} ${
              successStudents.length > 1 ? 'students' : 'student'
            } successfully updated`"
            variant="rg"
            type="body"
            class="mb-2"
          />
        </div>
        <div v-if="failedStudents.length > 0">
          <AppTypographyText
            label="Student updates failed"
            variant="bd"
            type="subtitle"
            class="mb-2"
          />
          <AppTypographyText
            :label="failedGeneralMessage"
            variant="rg"
            type="body"
            class="mb-2"
          />
          <ul class="list-disc pl-[1.5em]">
            <li
              v-for="(student, i) in failedStudents"
              :key="i"
              class="text-flohh-text-body"
            >
              {{ student.emailAddress }} ({{ student.reason }})
            </li>
          </ul>
        </div>
        <div class="flex items-center justify-end w-full">
          <AppButton type="submit" @click="handleCloseModal">
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Finish
          </AppButton>
        </div>
      </div>
      <div v-else class="w-full px-4 text-center py-7">
        <AppTypographyText
          label="Some of your student accounts don't have an email. Add them below to continue."
          variant="bd"
          type="title"
          class="mb-7"
        />
        <div
          v-if="studentDataPayload && studentDataPayload.length > 0"
          class="flex flex-col gap-2 mb-5"
        >
          <StudentItem
            v-for="(item, index) in studentDataPayload"
            :key="index"
            :index="index"
            :student="item"
            @onStudentUpdate="handleStudentUpdate"
            @onRemoveStudent="handleRemoveStudent"
          />
        </div>

        <div class="flex items-center justify-end w-full">
          <AppButton
            type="submit"
            :loading="isLoading"
            :disabled="isLoading"
            @click="updateStudentAccounts"
          >
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Update Student Accounts
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Prop, Vue, Model, Watch } from "vue-facing-decorator";
import { useToast } from "primevue/usetoast";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import StudentService from "@/services/StudentService";
import { AxiosResponse, AxiosError } from "axios";
import FormField from "@/components/Layout/Forms/FormField.vue";
import emitter from "@/config/emitter";
import AppTypographyText from "./../../Layout/Typhography/AppTypographyText.vue";
import StudentItem from "./StudentItem.vue";
import { AssignmentSubmissionSummaryStudent } from "@/store/assignment/assignmentTypes";

interface FailedStudentUpdate extends AssignmentSubmissionSummaryStudent {
  reason: string;
  emailAddress: string;
}

@Component({
  components: {
    AppButton,
    ModalUtility,
    FormTextInput,
    FormField,
    AppTypographyText,
    StudentItem,
  },
})
export default class AddEmailForStudentModal extends Vue {
  private studentService: StudentService = new StudentService();
  eventBus = emitter;

  @Model({
    default: false,
    required: false,
  })
  modalOpen!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  studentData!: AssignmentSubmissionSummaryStudent[];

  @Prop({
    type: String,
    required: true,
  })
  classUuid!: string;

  icon = AppIcons;
  toast = useToast();

  isLoading = false;

  firstName = "";
  lastName = "";
  email = "";

  showResult = false;
  successStudents: AssignmentSubmissionSummaryStudent[] = [];
  failedStudents: FailedStudentUpdate[] = [];
  failedGeneralMessage = "";

  studentDataPayload!: AssignmentSubmissionSummaryStudent[];

  @Watch("modalOpen")
  modalOpenWatcher(value: boolean) {
    if (value) {
      this.setData();
    } else {
      this.showResult = false;
      this.successStudents = [];
      this.failedStudents = [];
      this.failedGeneralMessage = "";
    }
  }

  handleRemoveStudent(index: number) {
    const studentData = structuredClone(this.studentDataPayload);
    if (studentData.length > 1) {
      studentData.splice(index, 1);
      this.studentDataPayload = studentData;
    }
  }

  mounted() {
    this.setData();
  }

  setData() {
    this.studentDataPayload = structuredClone(this.studentData);
  }

  handleStudentUpdate(data: {
    index: number;
    student: AssignmentSubmissionSummaryStudent;
  }) {
    const studentData = structuredClone(this.studentDataPayload);
    studentData[data.index] = data.student;

    this.studentDataPayload = studentData;
  }

  validateForm() {
    const studentDataPayload = structuredClone(this.studentDataPayload);
    const incompleteDetails = studentDataPayload.filter(
      (student: AssignmentSubmissionSummaryStudent) =>
        !student.profile.email ||
        !student.profile.firstName ||
        !student.profile.lastName
    );

    if (incompleteDetails && incompleteDetails.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  handleCloseModal() {
    this.modalOpen = false;

    if (this.successStudents.length > 0 && this.showResult) {
      this.eventBus.emit("REFRESH_SELECTED_CLASS");
    }
  }

  async updateStudentAccounts() {
    const isValid = this.validateForm();
    if (isValid) {
      this.isLoading = true;
      const successStudents: AssignmentSubmissionSummaryStudent[] = [];
      const failedStudents: FailedStudentUpdate[] = [];
      for (let i = 0; i < this.studentDataPayload.length; i++) {
        const item = this.studentDataPayload[i];
        const profile = item.profile;
        const payload = {
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
        };
        try {
          const response: AxiosResponse = await this.studentService.putStudent(
            payload,
            item.uuid,
            this.classUuid
          );
          if (response.data.ok) {
            successStudents.push(item);
          }
        } catch (error) {
          console.error(error);
          const err = error as AxiosError;
          const errorResponse = err.response as AxiosResponse;

          if (errorResponse) {
            failedStudents.push({
              ...item,
              reason: errorResponse.data.message,
              emailAddress: payload.email,
            });
          }
        } finally {
          this.isLoading = false;
        }
      }
      this.successStudents = successStudents;
      this.failedStudents = failedStudents;

      // Temporary fix
      const hasExistingEmail = failedStudents.find(
        (item: FailedStudentUpdate) => item.reason.includes("already used")
      );
      if (hasExistingEmail) {
        this.failedGeneralMessage =
          "Emails already in use. Some of the emails that you have entered are already linked to other accounts. To resolve this issue please exit your return feedback modal, and navigate to the invite students modal, invite the students to your class, and then reassign the submissions to the correct accounts. If you need assistance please don't hesitate to contact our support team.";
      } else {
        this.failedGeneralMessage = "";
      }

      this.showResult = true;
    } else {
      this.showToast("error", `Please fill up all fields`);
    }
  }

  showToast(
    severity: "success" | "info" | "warn" | "error" | undefined,
    message: string
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 5000,
    });
  }
}
</script>
