<template>
  <ModalUtility
    v-model="visible"
    width="100%"
    title="Your Account Credits"
    @onClose="toggleModal"
    containerStyle="!w-full max-w-[614px]"
    :draggable="false"
  >
    <template #header>
      <div
        class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
      >
        <div
          class="absolute top-50 right-50 px-4 flex flex-col items-start justify-center w-full"
        >
          <h1 class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20">
            Your Account Credits
          </h1>
          <!-- Use for teleportation don't remove this tag -->
          <p id="modal-subtitle-teleport" class="font-semibold"></p>
          <!-- end of teleportation -->
        </div>
        <div class="btn-close-wrap absolute top-[10px] right-[10px]">
          <button class="p-2 text-white rounded-full" @click="handleClickClose">
            <img src="@/assets/close.svg" class="w-4" />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div
        class="px-[24px] py-[28px]"
        v-if="
          (activePlan?.credits?.available &&
            Number(activePlan?.credits?.available) > 0) ||
          activePlan?.credits?.available === 'unlimited'
        "
      >
        <p class="px-[12px] py-[16px] text-[14px] text-[#333] font-medium">
          You’re currently on a {{ activePlan?.subscription?.plan.name }} plan.
        </p>
        <p class="px-[12px] py-[16px] text-[14px] text-[#333] font-medium">
          You’ve been given {{ activePlan?.credits?.total }} credits to get
          started with marking student submissions. Use these credits to explore
          all of the benefits of Flohh. Once you reach your limit, upgrade to
          continue grading and unlock even more features.
        </p>
        <p
          class="px-[12px] py-[16px] text-[14px] text-[#333] font-medium flex items-center gap-[8px] capitalize"
        >
          <span class="block w-[24px] px-[2px] min-w-[24px]">
            <img src="@/assets/icon-coin.svg" class="w-full h-[auto]" />
          </span>
          {{ activePlan?.credits?.available }} Submissions Credits Remaining
        </p>
      </div>
      <div class="px-[24px] py-[28px]" v-else>
        <p class="px-[12px] py-[16px] text-[14px] text-[#333] font-medium">
          You’ve used all of your submission credits.
        </p>
        <p
          class="px-[12px] py-[16px] text-[14px] text-[#333] font-medium flex items-center gap-[8px]"
        >
          <span class="block w-[24px] px-[2px] min-w-[24px]">
            <img src="@/assets/icon-coin.svg" class="w-full h-[auto]" />
          </span>
          {{ activePlan?.credits?.available }} Submissions Credits Remaining
        </p>
        <p class="px-[12px] py-[16px] text-[14px] text-[#333] font-medium">
          Upgrade your account to continue grading and unlock even more
          features.
        </p>
      </div>
    </template>
    <template #footer>
      <div
        class="flex pt-5 border-t border-solid border-flohh-neutral-85 justify-end"
      >
        <AppButton
          @click="handleView"
          type="submit"
          label="View plans & upgrade"
          iconLeft="eyeActive"
        />
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from "vue-facing-decorator";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import { icons } from "@/utils/icons";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { ActivePlan } from "../UpgradePlan/type";
@Component({
  components: {
    ProgressLoader,
    ModalUtility,
    AppButton,
  },
})
export default class AccountCreditsComponent extends Vue {
  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Prop({
    required: true,
  })
  activePlan!: ActivePlan | undefined;

  @Prop({
    type: String,
    required: false,
  })
  isRedirectBack!: string;

  icon = icons;

  mounted() {
    //
  }

  handleClickClose() {
    this.visible = false;
    if (this.activePlan?.credits?.available === 0 && this.isRedirectBack) {
      this.$router.push(this.isRedirectBack);
    }
  }

  toggleModal() {
    this.visible = !this.visible;
  }

  handleView() {
    //
    this.visible = false;
    this.$emit("toggleUpgradePlanModal", true);
  }
}
</script>

<style scoped lang="scss"></style>
