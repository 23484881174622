import axios from "axios";

const BASE_URL = "/accounts";

export default class AccountsPortalService {
  updateInvitation(uuid: string, action: "accept" | "decline") {
    return axios.post(
      `${BASE_URL}/subscription/pending-actions/${uuid}/${action}`
    );
  }
}
