<template>
  <section
    class="relative min-h-screen overflow-hidden main-container bg-flohh-neutral-95"
  >
    <div class="kanban-wrap">
      <div class="relative z-10 pb-10 bg-white rounded-lg">
        <div class="text-center">
          <div class="flex items-center justify-between p-10">
            <h6
              class="text-flohh-neutral-20 text-flohh-h6 font-flohh-font-bold"
            >
              This is where you are at:
            </h6>
            <div v-if="isTeacher" class="flex justify-between gap-2">
              <AppButton type="submit" @click="handleClickAssignment">
                <span v-html="icons.plusBlack" class="mr-2"></span>
                Assignment
              </AppButton>
              <CreateClassButton />
            </div>
          </div>

          <div
            class="kanban-heading pl-[42px] pr-10"
            v-if="
              !loadingDashboard &&
              (layout.length > 0 || studentLayout.length > 0)
            "
          >
            <div
              class="kanban-col"
              v-for="(item, index) in isTeacher
                ? headingLayout
                : studentHeadingLayout"
              :key="index"
            >
              <div>
                <span
                  class="cell-heading font-flohh-font-medium text-flohh-text-body text-flohh-neutral-20"
                >
                  {{ item.i }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="px-8 kanban-content"
          v-if="
            !loadingDashboard && (layout.length > 0 || studentLayout.length > 0)
          "
        >
          <template
            v-for="(item, index) in isTeacher
              ? headingLayout
              : studentHeadingLayout"
            :key="index"
          >
            <GridLayout
              :layout="isTeacher ? layout[index] : studentLayout[index]"
              :is-draggable="true"
              :is-resizable="isResizable"
              :is-bounded="isBounded"
              :col-num="item.isPreventCollision ? 6 : 3"
              :row-height="52"
              :vertical-compact="isVerticalCompact"
              :max-row="2"
              :preventCollision="item.isPreventCollision"
            >
              <template
                v-for="c in isTeacher ? layout[index] : studentLayout[index]"
              >
                <grid-item
                  @move="moveEvent"
                  @moved="movedEvent"
                  :static="c.static"
                  :x="c.x"
                  :y="c.y"
                  :w="c.w"
                  :h="c.h"
                  :i="c.i"
                  :key="c.i"
                  :class="c.o.isBlank ? 'is-blank' : ''"
                  v-if="item.isPreventCollision"
                  drag-allow-from=".vue-draggable-handle"
                  drag-ignore-from=".no-drag"
                >
                  <KanbanCellComponent
                    :key="c.i"
                    :data="c.o.data"
                    :assignments="assignments"
                  />
                </grid-item>

                <grid-item
                  @moved="movedEvent"
                  :static="c.static"
                  :x="c.x"
                  :y="c.y"
                  :w="c.w"
                  :h="c.h"
                  :i="c.i"
                  :key="c.i"
                  :class="c.o.isBlank ? 'is-blank' : ''"
                  v-if="!item.isPreventCollision"
                  drag-allow-from=".vue-draggable-handle"
                  drag-ignore-from=".no-drag"
                >
                  <KanbanCellComponent
                    :key="c.i"
                    :data="c.o.data"
                    :assignments="assignments"
                  />
                </grid-item>
              </template>
            </GridLayout>
          </template>
        </div>
        <div
          class="w-full h-[500px] flex flex-col items-center justify-center"
          v-if="
            !loadingDashboard &&
            layout.length === 0 &&
            studentLayout.length === 0 &&
            showNoListMessage
          "
        >
          <AppTypographyText
            variant="md"
            type="title"
            label="No Available Assignments"
          />
        </div>
        <div v-if="loadingDashboard" class="w-full h-screen px-8">
          <div class="flex flex-row w-full h-full gap-x-4">
            <div
              v-for="n in 5"
              :key="n"
              class="flex-1 bg-flohh-neutral-95 h-full animate-pulse rounded-[8px]"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="parent-div mt-[30rem] fixed h-[370px] bottom-[-165px] w-full bg-[url('@/assets/bg-dashboard-pattern.png')] bg-repeat-x bg-left-top bg-[auto_60%]"
    ></div>
  </section>

  <ModalUtility v-model="isOpenAssignment" title="Create an assignment">
    <template #content>
      <div class="bg-white">
        <CreateAssignment
          @onClickDone="isOpenAssignment = false"
          :maximize="true"
        />
      </div>
    </template>
  </ModalUtility>

  <ModalUtility
    :contentStyle="{ borderRadius: '4px' }"
    :width="'900px'"
    :showHeader="false"
    v-model="isAddClassOpen"
  >
    <template #content="{ handleClickClose }">
      <CreateClassComponent :handleClickClose="handleClickClose" />
    </template>
  </ModalUtility>

  <UpdatesModal
    v-if="pendingActions && pendingActions.length > 0"
    v-model="isSessionModalOpen"
    :updates="pendingActions"
    @onUpdatesModalClose="handleShowOnboardingVideo"
  />

  <ModalUtility v-model="showOnboardingVideo">
    <template #header="{ handleClickClose }">
      <div
        class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
      >
        <div
          class="absolute flex flex-col items-start justify-center w-full px-4 top-50 right-50"
        >
          <h1 class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20">
            Is Flohh the right app for you?
          </h1>
          <p class="font-flohh-font-medium text-flohh-text-body">
            New teacher welcome video
          </p>
          <!-- Use for teleportation don't remove this tag -->
          <p id="modal-subtitle-teleport" class="font-semibold"></p>
          <!-- end of teleportation -->
        </div>
        <div class="btn-close-wrap absolute top-[10px] right-[10px]">
          <button
            class="p-2 text-white rounded-full"
            @click="
              () => {
                handleOnboardingVideo('hide');
                handleClickClose();
              }
            "
          >
            <img src="@/assets/close.svg" class="w-4" />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="bg-white p-[20px]">
        <p class="mb-1 font-flohh-font-medium text-flohh-text-subtitle">
          Hey {{ firstName }}!
        </p>
        <p class="mb-4 font-flohh-font-medium text-flohh-text-subtitle">
          Welcome to Flohh! We are stoked you are here. Here's a quick message
          from our founder to let you know how to get started using the
          platform.
        </p>
        <div
          style="
            position: relative;
            padding-bottom: 50.31250000000001%;
            height: 0;
          "
        >
          <iframe
            src="https://www.loom.com/embed/6804e0699b8e435a86d4cf2db303f27e?sid=49733bf6-ace5-4412-8ab5-e9c4f175da26"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          ></iframe>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { reactive } from "vue";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import { GridLayout, GridItem } from "grid-layout-plus";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import CreateClassButton from "../CreateClassPopup/GroupPopup/CreateClassButton.vue";
import KanbanCellComponent from "./KanbanCellComponent.vue";
import UpdatesModal from "./Updates/UpdatesModal.vue";

import {
  HEADING_LAYOUT,
  LAYOUT,
  STUDENT_HEADING_LAYOUT,
  STUDENT_LAYOUT,
} from "./template";
import { ILayoutItem, ILayoutItemRequired, TLayout } from "./types";
import { StorageDataKey } from "@/enums/enums";
import ModalUtility from "../utilities/ModalUtility.vue";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment.vue";
import CreateClassComponent from "../CreateClassModal/CreateClassComponent.vue";
import emitter from "@/config/emitter";
import {
  AssignmentDashboard,
  StudentDashboard,
  PendingActions,
} from "@/store/dashboard/dashboardTypes";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import DashboardService from "@/services/DashboardService";
import axios, { AxiosResponse } from "axios";
@Component({
  components: {
    GridLayout,
    GridItem,
    KanbanCellComponent,
    AppButton,
    ModalUtility,
    CreateAssignment,
    CreateClassComponent,
    CreateClassButton,
    AppTypographyText,
    UpdatesModal,
  },
})
export default class StudentDashboardComponent extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  loadingDashboard!: boolean;

  isDraggable = true;
  isResizable = false;
  isBounded = true;
  isVerticalCompact = true;
  isTeacher = true;

  icons = AppIcons;
  assignments: AssignmentDashboard[] | StudentDashboard[] = [];

  isAddClassOpen = false;
  isOpenAssignment = false;
  eventBus = emitter;
  isSessionModalOpen = false;
  pendingActions!: PendingActions[];

  showOnboardingVideo = false;
  firstName = "";

  handleAddClass() {
    this.isAddClassOpen = true;
  }

  handleClickAssignment() {
    this.isOpenAssignment = true;
  }
  async handleOnboardingVideo(status?: string) {
    const storageKey = "ShowOnboardingView";
    const video = localStorage.getItem(storageKey);
    if (video) {
      this.showOnboardingVideo = false;
    } else {
      if (this.isTeacher) {
        this.showOnboardingVideo = true;
      } else {
        this.showOnboardingVideo = false;
      }
      if (status === "hide") {
        localStorage.setItem(storageKey, JSON.stringify(false));
        const response: AxiosResponse = await axios.post(
          "/teachers/onboarding",
          { watchedOnboardingVideo: true }
        );
      }
    }
  }

  intervalId!: any;
  showNoListMessage = false;
  // layout = reactive(
  //   JSON.parse(
  //     localStorage.getItem(StorageDataKey.KanbanData) || JSON.stringify(LAYOUT)
  //   )
  // );
  layout: any = reactive([]);
  headingLayout = reactive(JSON.parse(JSON.stringify(HEADING_LAYOUT)));

  // studentLayout = reactive(
  //   JSON.parse(
  //     localStorage.getItem(StorageDataKey.StudentKanbanData) ||
  //       JSON.stringify(STUDENT_LAYOUT)
  //   )
  // );
  studentHeadingLayout = JSON.parse(JSON.stringify(STUDENT_HEADING_LAYOUT));
  studentLayout: any = reactive([]);

  get assignmentState() {
    return this.$store.state.assignment.selectedAssignment;
  }

  @Watch("loadingDashboard")
  refreshDashboardWatcher(value: boolean) {
    if (!value) this.handleSetData();
  }

  unmounted() {
    //
  }

  async mounted() {
    const teacherData = localStorage.getItem("teacher");
    if (teacherData) {
      this.firstName = JSON.parse(teacherData).profile.firstName;
    }
    // this.handleOnboardingVideo();

    this.isTeacher = this.$route.path === "/dashboard";

    this.intervalId = setInterval(this.handleSetData, 1000);

    this.eventBus.on("RELOAD_DASHBOARD", () => {
      location.reload();
    });

    const userSession = localStorage.getItem("userSession");
    if (!userSession) {
      const response: AxiosResponse = await axios.get("/session");
      if (response.data.ok) {
        const pendingActions = response.data.data.pendingActions;
        if (pendingActions && pendingActions.length > 0) {
          const learningGoalActions = pendingActions.find(
            (item: PendingActions) => item.type === "learningGoals"
          );
          const onboardingVideo = pendingActions.find(
            (item: PendingActions) => item.type === "onboardingVideo"
          );
          if (learningGoalActions) {
            this.pendingActions = pendingActions;
            this.isSessionModalOpen = true;
          } else {
            if (onboardingVideo) {
              this.isSessionModalOpen = false;
              this.handleOnboardingVideo();
            }
          }
          localStorage.setItem("userSession", JSON.stringify(pendingActions));
        }
      }
    }

    // Watch for new assignments
    this.$store.watch(
      (state) => state.assignment.selectedAssignment,
      async (newVal, oldVal) => {
        const newAssignment = structuredClone(newVal);
        const i = this.assignments.findIndex(
          (assignmet) => assignmet.uuid === newAssignment.uuid
        );
        if (i === -1) {
          newAssignment.submissionCounts = {
            completedSubmissions: 0,
            markedSubmissions: 0,
            totalSubmissions: 0,
          };
          this.assignments.push(newAssignment);
          if (this.isTeacher) {
            this.handleDataMappingTeacher(this.assignments);
          } else {
            this.handleDataMappingStudent(this.assignments);
          }
        }
      },
      { deep: true }
    );
  }

  handleShowOnboardingVideo() {
    const userSession = localStorage.getItem("userSession");
    if (userSession) {
      const onboardingVideo = JSON.parse(userSession).find(
        (item: PendingActions) => item.type === "onboardingVideo"
      );
      if (this.isTeacher && onboardingVideo) {
        this.showOnboardingVideo = true;
      } else {
        this.showOnboardingVideo = false;
      }
    }
  }

  handleSetData() {
    if (this.layout.length || this.studentLayout.length) {
      clearInterval(this.intervalId);
    }
    if (this.isTeacher) {
      this.handleTeacherAssignments();
    } else {
      this.handleStudentDashboard();
    }
    setTimeout(() => (this.showNoListMessage = true), 500);
  }

  async handleTeacherAssignments() {
    this.assignments = structuredClone(
      this.$store.getters["dashboard/getTeacherAssignments"]
    );
    this.handleDataMappingTeacher(this.assignments);
  }

  handleStudentDashboard() {
    this.assignments = structuredClone(
      this.$store.getters["dashboard/getStudentAssignments"]
    );
    this.handleDataMappingStudent(this.assignments);
  }

  handleDataMappingTeacher(data: any) {
    if (data.length) {
      const newData: any = [];
      this.headingLayout.map(() => {
        newData.push([]);
      });

      data.forEach((item: any, index: number) => {
        const lgSubmitted = item.submissionCounts.learningGoalsToReview;
        const lgRequested = item.submissionCounts.learningGoalsRequested;
        const done = item.submissionCounts.completedSubmissions;
        const marked = item.submissionCounts.markedSubmissions;
        const total = item.submissionCounts.totalSubmissions;
        // TODO: Remove this line when BE is deployed 14/03/2024
        const requested = lgRequested ? lgRequested : 0;
        const numerator = done + marked + requested + lgSubmitted;
        const perc = (numerator / total) * 100;
        let newIndex = this.roundToNearestPercentageIndex(perc, "teacher");
        newData[newIndex].push({
          x: 0,
          y: index * 2,
          w: 3,
          h: 2,
          i: item.uuid,
          o: {
            data: {
              type: "submission",
              done: done,
              marked: marked,
              total: total,
              numerator: numerator,
              denominator: total,
              name: item.title,
              subject: item.class.subject,
              subjectClass: item.class.code,
              assignmentUuid: item.uuid,
              classUuid: item.class.uuid,
            },
            color: "#fff",
            isBlank: false,
          },
          static: false,
        });
        if (lgSubmitted > 0 || done > 0) {
          const denominator = lgSubmitted + done;
          const perc = (done / denominator) * 100;
          let lgNewIndex = this.roundToNearestPercentageIndex(perc, "teacher");
          newData[lgNewIndex].push({
            x: 0,
            y: index * 2,
            w: 3,
            h: 2,
            i: item.uuid + Math.random().toString(),
            o: {
              data: {
                type: "goal",
                done: done,
                marked: marked,
                total: total,
                numerator: done,
                denominator: denominator,
                name: item.title,
                subject: item.class.subject,
                subjectClass: item.class.code,
                assignmentUuid: item.uuid,
                classUuid: item.class.uuid,
              },
              color: "#fff",
              isBlank: false,
            },
            static: false,
          });
        }
      });
      this.layout = newData;
      localStorage.setItem(StorageDataKey.KanbanData, JSON.stringify(newData));
    }
  }

  handleDataMappingStudent(data: any) {
    if (data.length) {
      const newData: any = [];
      this.studentHeadingLayout.map(() => {
        newData.push([]);
      });
      data.forEach((item: any, index: number) => {
        let newIndex = 0;
        if (item.assignmentStatus === "overdue") {
          newIndex = 1;
        } else if (item.assignmentStatus === "submitted") {
          newIndex = 2;
        } else if (item.assignmentStatus === "feedback returned") {
          newIndex = 3;
        } else if (item.assignmentStatus === "completed") {
          newIndex = 4;
        } else {
          newIndex = 0;
        }

        const types = [
          "viewAndSubmit",
          "viewAndSubmit",
          "niceWork",
          "feedback",
          "viewGrade",
        ];

        newData[newIndex].push({
          x: 0,
          y: index * 2,
          w: 3,
          h: 2,
          i: item.uuid,
          o: {
            data: {
              type: types[newIndex],
              name: item.title,
              subject: item.class.subject,
              subjectClass: item.class.code,
              summary: item,
            },
            color: "#fff",
            isBlank: false,
          },
          static: false,
        });
      });
      this.studentLayout = newData;
      localStorage.setItem(StorageDataKey.KanbanData, JSON.stringify(newData));
    }
  }

  roundToNearestPercentageIndex(value: number, role: string): number {
    const percentages =
      role === "teacher" ? [0, 25, 50, 75, 100] : [1, 2, 3, 4, 5];
    const nearestIndex = percentages.reduce((prevIndex, curr, currIndex) => {
      return Math.abs(curr - value) < Math.abs(percentages[prevIndex] - value)
        ? currIndex
        : prevIndex;
    }, 0);
    return nearestIndex;
  }

  moveEvent(i: number, newX: number, newY: number) {
    let p;
    const layoutOne = this.layout[1] as any[];
    const iIndex = layoutOne.findIndex((o: ILayoutItemRequired) => o.i === i);
    for (p = 0; p < layoutOne.length; p++) {
      //Horizontal swapping
      if (
        newX >= this.layout[1][p]["x"] &&
        newX < this.layout[1][p]["x"] + this.layout[1][p]["w"] &&
        this.layout[1][iIndex]["y"] == this.layout[1][p]["y"] &&
        i != this.layout[1][p]["i"]
      ) {
        let initialX = this.layout[1][iIndex]["x"];
        let finalX = this.layout[1][p]["x"];
        this.layout[1][iIndex]["x"] = finalX;
        this.layout[1][p]["x"] = initialX;
      }
      //Vertical swapping
      if (
        newY >= this.layout[1][p]["y"] &&
        newY < this.layout[1][p]["y"] + 1 &&
        this.layout[1][iIndex]["x"] == this.layout[1][p]["x"] &&
        i != this.layout[1][p]["i"]
      ) {
        let initialY = this.layout[1][iIndex]["y"];
        let finalY = this.layout[1][p]["y"];
        this.layout[1][iIndex]["y"] = finalY;
        this.layout[1][p]["y"] = initialY;
      }
    }
  }

  movedEvent() {
    this.isTeacher
      ? localStorage.setItem(
          StorageDataKey.KanbanData,
          JSON.stringify(this.layout)
        )
      : localStorage.setItem(
          StorageDataKey.StudentKanbanData,
          JSON.stringify(this.studentLayout)
        );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-container {
  .kanban-wrap {
    margin-left: 0;
    padding: 17px;
    position: relative;

    .kanban-heading {
      display: flex;
      width: 100%;

      .kanban-col {
        background-color: #f4cddc;
        border-radius: 8px;
        padding: 9px 0;
        width: 100%;

        &:not(:last-child) {
          margin-right: 14.5px;
        }
      }
    }

    .kanban-content {
      padding-right: 35px !important;
      display: flex;

      .vgl-layout {
        margin-right: 13px !important;
        width: 100% !important;
        --vgl-placeholder-bg: white; // remove box red bg when dragging

        .vgl-item {
          width: 100% !important;
        }
      }
    }

    .is-blank {
      visibility: hidden !important;
      pointer-events: none !important;
    }
  }
}
</style>
