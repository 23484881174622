<script lang="ts">
import { Vue, Component, Model, Emit } from "vue-facing-decorator";
import AppButton from "../Layout/Buttons/AppButton.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import FormField from "../Layout/Forms/FormField.vue";
import ClassInformation from "./ClassInformation.vue";
import { Icon, icons } from "@/utils/icons";
import { Class, GoogleClassPayload } from "@/models/Class";
import ClassService from "@/services/ClassService";
import { ClassInfo } from "@/store/createClass/types";
import { useToast } from "primevue/usetoast";

@Component({
  components: {
    AppButton,
    ModalUtility,
    FormField,
    ClassInformation,
  },
})
export default class CreateClassGooglePopup extends Vue {
  private classService = new ClassService();
  toast = useToast();
  @Model({
    type: Boolean,
  })
  openDialog!: boolean;

  icon: Icon = icons;

  selectedClass = "";

  SAMPLE_CLASS = [
    "Mathematics",
    "English Language Arts",
    "Science",
    "Social Studies",
    "Physical Education (PE)",
    "Art",
    "Music",
    "Computer Science",
    "History",
    "Foreign Language",
  ];

  googleClasses = [];

  isLoading = true;
  classLoading = true;
  isSaving = false;

  classUuid = "";
  token = "";

  @Emit("onSaveGoogleImport")
  handleSaveGoogleImport(message: string, status: string) {
    const classInfo: ClassInfo =
      this.$store.getters["createClassPopup/classInformation"];
    if (classInfo && classInfo.uuid) {
      if (this.$route.query && this.$route.query.assignment) {
        this.$router.push({
          name: "Classes",
          params: { id: classInfo.uuid },
          query: { assignment: this.$route.query.assignment },
        });
        return;
      }
      this.$router.push({
        name: "Classes",
        params: { id: classInfo.uuid },
      });
    }
    return { message, status };
  }

  mounted() {
    this.onGetClassProfile();
  }

  async onGetClassProfile() {
    try {
      let classId = "";
      if (this.$route.params.id) {
        classId = this.$route.params.id as string;
      } else {
        const classInfo = localStorage.getItem("ClassInfo");
        if (classInfo) {
          const parsedClassInfo = JSON.parse(classInfo);
          classId = parsedClassInfo.uuid;
        }
      }
      // classId = classInfo.uuid ? classInfo.uuid : "";
      this.classUuid = classId;
      if (classId) {
        const response = await this.classService.getClassById(classId);
        const classInformation = response.data.data as Class;
        await this.$store.dispatch(
          "createClassPopup/setClassInfo",
          classInformation
        );
      }
      this.isLoading = false;
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("token")) {
        this.token = urlParams.get("token") || "";
        this.getGoogleClassroom(this.token);
      }
      // window.history.replaceState(null, "", window.location.pathname);
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    } finally {
      //
      this.isLoading = false;
    }
  }

  async getGoogleClassroom(token: string) {
    this.classLoading = true;
    //
    try {
      this.classLoading = false;
      const res = await this.classService.getGoogleClassroom(token);
      if (res.data.ok) {
        //
        const data: any = res.data.data;
        if (data.length) {
          this.googleClasses = data.map((o: any) => ({
            value: o.id,
            label: o.code,
          }));
        } else {
          this.toast.add({
            severity: "error",
            detail: `No google class found.`,
            life: 3000,
          });
        }
      }
    } catch (e) {
      this.classLoading = false;
    } finally {
      this.classLoading = false;
    }
  }

  // async handleSelectedClass(classId: string) {
  //   //
  //   const res = await this.classService.postGoogleClassroomImport();
  // }

  handleCloseInvite() {
    this.$store.dispatch("createClassPopup/resetClass");
  }

  @Emit("onCloseSkipOrGoBack")
  handleClickSkipOrGoBack(index = 0) {
    return index;
  }

  async handleClickSave() {
    this.isSaving = true;
    try {
      if (this.selectedClass) {
        const payload: GoogleClassPayload = {
          flohhClassUuid: this.classUuid,
          token: this.token,
        };
        const res = await this.classService.postGoogleClassroomImport(
          this.selectedClass,
          payload
        );
        this.isSaving = false;

        const cls: any = this.googleClasses.find(
          (o: any) => o.id === this.selectedClass
        );
        if (cls) {
          this.$store.dispatch("createClassPopup/setClass", cls.code);
        }
        let message = "Import Students successfully";
        let severity = "success";
        if (res.data.ok) {
          const data = res.data.data;
          if (data.failedToAddStudentCount > 0) {
            if (data.addedStudentCount > 0) {
              message = `${data.addedStudentCount} student imported and ${data.failedToAddStudentCount} student already in class.`;
              severity = "success";
            } else {
              message = `${data.failedToAddStudentCount} student already in class.`;
              severity = "error";
            }
          } else {
            message = `${data.addedStudentCount} students imported.`;
            severity = "success";
          }
        }
        this.handleSaveGoogleImport(message, severity);
      } else {
        this.toast.add({
          severity: "error",
          detail: `Please select a class.`,
          life: 3000,
        });
      }
    } catch (e) {
      //
      this.isSaving = false;
    } finally {
      this.isSaving = false;
    }

    // this.$store.dispatch("createClassPopup/setClass", this.selectedClass);
  }
}
</script>
<template>
  <ModalUtility
    v-model="openDialog"
    width="45vw"
    title="Add your students"
    @onClose="handleCloseInvite"
    v-if="!isLoading"
  >
    <template #content>
      <div class="px-4">
        <div class="py-2 mt-5">
          <FormField label="Class Information">
            <ClassInformation />
          </FormField>
        </div>
        <div class="w-full py-4">
          <FormField
            :loading="classLoading"
            v-model="selectedClass"
            label="Select Class"
            option-value="value"
            option-label="label"
            type="select"
            :options="googleClasses"
            placeholder="Select a class"
            :filter="true"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="flex flex-row pt-5 border-t border-solid border-flohh-neutral-85"
      >
        <div class="flex items-center justify-start flex-1">
          <AppButton text @click="handleClickSkipOrGoBack()">
            Go Back
          </AppButton>
        </div>
        <div class="flex items-center justify-end flex-1 gap-x-4">
          <AppButton text @click="handleClickSkipOrGoBack(1)">
            Skip this step
          </AppButton>
          <AppButton
            type="submit"
            @click="handleClickSave"
            :loadingLabel="isSaving ? 'Saving...' : ''"
            :loading="isSaving"
          >
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
            Save
          </AppButton>
        </div>
      </div>
    </template>
  </ModalUtility>
</template>
