<template>
  <ModalUtility title="My Account" class="w-[800px]" v-model="isVisible">
    <template #content>
      <div class="bg-white w-full h-[500px] px-4 py-2 pb-10">
        <div class="w-full">
          <div class="flex justify-start w-full mt-5">
            <h4 class="font-semibold">Personal Details</h4>
          </div>
          <div class="px-6 py-6">
            <div
              class="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
            >
              <div class="flex flex-col items-start justify-start col-span-1">
                <p class="font-semibold">Name</p>
                <p class="text-sm">
                  {{ userProfile?.profile.firstName }}
                  {{ userProfile?.profile.lastName }}
                </p>
              </div>
              <div class="flex flex-col items-start justify-start col-span-1">
                <p class="font-semibold">Email</p>
                <p class="text-sm">{{ userProfile?.email }}</p>
              </div>
            </div>
          </div>
          <div class="flex justify-start w-full">
            <h4 class="font-semibold">School Details</h4>
          </div>
          <div class="px-6 py-6">
            <div
              class="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
            >
              <div class="flex flex-col items-start justify-start col-span-1">
                <p class="font-semibold">School</p>
                <p class="text-sm">
                  {{ userProfile.schoolName }}
                </p>
              </div>
              <div class="flex flex-col items-start justify-start col-span-1">
                <p class="font-semibold">State/Province</p>
                <p class="text-sm">
                  {{ userProfile?.schoolAddress?.state }}
                </p>
              </div>
              <div class="flex flex-col items-start justify-start col-span-1">
                <p class="font-semibold">Country</p>
                <p class="text-sm">
                  {{ getCountryName(userProfile?.schoolAddress?.country) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex w-full h-10">
          <div class="grow" />
          <button
            @click="handleClickEdit"
            class="flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium px-[20px] py-[12px] bg-flohh-secondary-green hover:bg-flohh-secondary-green-dark text-flohh-text-body h-[45px]"
          >
            <img src="@/assets/icon-pencil.svg" class="" />
            Edit
          </button>
        </div>
      </div>
    </template>
  </ModalUtility>
  <EditMyAccountPopup
    v-model="isEditMyAccountModalOpen"
    :userProfile="userProfile"
    :role="role"
  />
</template>
<script lang="ts">
import { Vue, Model, Component, Prop } from "vue-facing-decorator";
import EventBus from "@/config/emitter";
import { ProfileDetails, getProfileDetails } from "@/config/auth";
import { getCountryName } from "@/utils/helper";

import ModalUtility from "../utilities/ModalUtility.vue";
import EditMyAccountPopup from "./EditMyAccountPopup.vue";

@Component({ components: { ModalUtility, EditMyAccountPopup } })
export default class MyAccount extends Vue {
  @Model({
    type: Boolean,
    default: false,
  })
  isVisible!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  role!: string;

  isEditMyAccountModalOpen = false;

  name = "";
  userProfile!: ProfileDetails;

  mounted() {
    this.userProfile = getProfileDetails(this.role);
    EventBus.on("UPDATE_MY_ACCOUNT", () => {
      this.userProfile = getProfileDetails(this.role);
      this.isVisible = true;
    });
  }

  handleClickEdit() {
    this.isEditMyAccountModalOpen = true;
  }

  handleClickClose() {
    this.isVisible = false;
  }

  getCountryName(code: string) {
    return getCountryName(code);
  }

  generateName() {
    this.name = `${this.userProfile.profile.firstName} ${this.userProfile.profile.lastName}`;
  }
}
</script>
