<template>
  <ModalUtility title="Edit My Account" v-model="isVisible">
    <template #content>
      <div class="bg-white w-full h-[600px] px-4 py-2">
        <EditMyAccountForm
          v-if="userProfile"
          v-model:firstName="firstName"
          v-model:lastName="lastName"
          v-model:email="email"
          v-model:country="country"
          v-model:name="school"
          v-model:state="state"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-center w-full pt-4">
        <AppButton
          type="submit"
          v-bind="$attrs"
          :loading="isSaving"
          @click="handleClickSaveMyAccount"
        >
          <template #icon_left>
            <span v-html="icons.checkBlack" class="pr-2"></span>
          </template>
          {{ isSaving ? "Loading" : "Save" }}
        </AppButton>
      </div>
    </template>
  </ModalUtility>
</template>
<script lang="ts">
import { Vue, Model, Component, Watch, Prop } from "vue-facing-decorator";
import EditMyAccountForm from "./MyAccountForm/EditMyAccountForm.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import Button from "primevue/button";
import EventBus from "@/config/emitter";
import { ProfileDetails } from "@/config/auth";
import axios, { AxiosResponse } from "axios";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import emitter from "@/config/emitter";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";
import { getCountryCode } from "@/utils/helper";

import { useToast } from "primevue/usetoast";
@Component({
  components: { EditMyAccountForm, ModalUtility, Button, AppButton },
})
export default class EditMyAccountPopup extends Vue {
  toast = useToast();
  @Model({
    type: Boolean,
    default: false,
  })
  isVisible!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  userProfile!: ProfileDetails;

  @Prop({
    type: String,
    required: true,
  })
  role!: string;

  firstName = "";
  lastName = "";
  email = "";
  country = "";
  school = "";
  state = "";

  isSaving = false;

  icons = AppIcons;
  eventBus = emitter;

  @Watch("isVisible")
  onVisibleChange(status: boolean) {
    if (status) {
      this.eventBus.emit("EVENT_TRIGGER", "G008");

      this.firstName = this.userProfile.profile.firstName || "";
      this.lastName = this.userProfile.profile.lastName || "";
      this.email = this.userProfile.email || "";
      this.school = this.userProfile.schoolName;
      this.country = getCountryCode(this.userProfile.schoolAddress?.country);
      this.state = this.userProfile.schoolAddress?.state || "";
    }
  }

  handleClickClose() {
    this.isVisible = false;
  }

  async handleClickSaveMyAccount() {
    try {
      this.eventBus.emit("EVENT_TRIGGER", "G009");
      const isStudent = this.role === "student";
      this.isSaving = true;

      const schoolAddress = {
        state: this.state,
        country: this.country,
      };

      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        schoolAddress: schoolAddress,
        schoolName: this.school,
      };

      if (payload.schoolName) {
        const endpoint = isStudent
          ? `/student/profile`
          : `/teachers/${this.userProfile.uuid}`;

        const response: AxiosResponse = await axios.put(endpoint, payload, {
          headers: TELEMETRY_HEAD.updateAccount,
        });

        if (response.data.ok) {
          localStorage.setItem(
            isStudent ? "student" : "teacher",
            JSON.stringify(response.data.data)
          );
          EventBus.emit("UPDATE_MY_ACCOUNT");
          this.isVisible = false;
          this.showMessage("Account has been updated.", "success");
        } else {
          this.isSaving = false;
          console.error(response.data.message);
        }
      } else {
        this.showMessage("School name is required.", "error");
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isSaving = false;
    }
  }

  showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>
