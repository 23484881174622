<template>
  <div
    class="flex flex-col h-full border-solid rounded-lg"
    :class="
      highlightedPlanSlug === plan.slug
        ? 'border-flohh-secondary-orange border-2'
        : 'border-flohh-neutral-85 border'
    "
  >
    <div
      class="border-b border-flohh-neutral-85 border-solid px-6 py-4 h-[24%]"
    >
      <p class="mb-1 text-flohh-text-subtitle font-flohh-font-medium">
        {{ plan.name }}
      </p>
      <h6 class="mb-1 text-flohh-h6 font-flohh-font-bold">
        {{ plan.slug === "organisation" ? "Let's Talk" : priceFormat() }}
      </h6>
      <p
        v-if="plan.slug === 'teacher-pro' || plan.slug === 'teams'"
        class="text-flohh-text-caption mb-2"
      >
        {{ planType }}, per seat
      </p>
      <br v-else />
      <p class="text-flohh-text-caption font-flohh-font-bold">
        {{ plan.description }}
      </p>
    </div>
    <div class="flex flex-col justify-between flex-1 p-6">
      <div>
        <div
          class="p-6 mb-6 border border-solid rounded-lg border-flohh-neutral-70"
        >
          <p class="text-[13px] text-flohh-black-20 mb-4">Includes:</p>
          <ul class="list-disc pl-[1.5em] text-[13px] text-flohh-neutral-35">
            <li v-for="(inclution, index) in plan.includes" :key="index">
              {{ inclution }}
            </li>
          </ul>
        </div>
        <div class="mb-3">
          <!-- <div
            class="rounded-[100px] px-2 py-1 bg-flohh-secondary-orange-200 inline-block mb-2"
          >
            <p class="text-[8.5px] text-flohh-black-20 font-flohh-font-bold">
              Full functionality until 1st Dec
            </p>
          </div> -->
          <div>
            <p class="mb-6 text-flohh-text-body font-flohh-font-medium">
              Features you'll love
            </p>
            <div
              v-for="(feature, index) in plan.features"
              :key="index"
              class="flex gap-2 mb-6"
            >
              <span v-html="icons.checkIconGreen"></span>
              <p class="text-flohh-text-body font-flohh-font-medium">
                {{ feature }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <AppButton
        v-if="plan.slug === activePlanSlug"
        label="Current"
        color="flohh-neutral-70"
        class="w-full mt-auto h-[34px] !bg-transparent border-solid border-[1px] !border-[#B3B3B3] opacity-[0.5] !text-[12px]"
        :disabled="true"
      />
      <AppButton
        v-else
        :label="upgradeButtonText"
        color="flohh-secondary-green"
        class="w-full h-[34px] !text-[12px]"
        @click="handleClick"
        type="submit"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import { icons } from "@/utils/icons";
import { Plan, ActivePlan, Price, PlanPricing } from "./type";
import AppButton from "../Layout/Buttons/AppButton.vue";

@Component({
  components: {
    AppButton,
  },
})
export default class PlanCard extends Vue {
  icons = icons;

  @Prop({
    type: Object,
    required: true,
  })
  plan!: Plan;

  @Prop({
    type: Object,
    required: true,
  })
  plans!: Plan[];

  @Prop({
    type: Object,
    required: true,
  })
  activePlan!: ActivePlan;

  @Prop({
    type: String,
    default: "monthly",
  })
  planType!: "monthly" | "yearly";

  @Prop({
    type: Number,
    default: 0,
  })
  seatCount!: number;

  upgradePlanModalOpen = false;
  cancelSubscriptionModalOpen = false;
  upgradeSeatModalOpen = true;
  updateSeatModalOpen = false;
  failedModalOpen = false;
  billingAddressFormOpen = false;

  activePlanSlug = "";
  upgradeButtonText = "";
  isUpgrade = true;

  seatCountModel = 0;
  highlightedPlanSlug = "";

  plansStore: any = {
    pricingTable: {
      monthly: [],
      yearly: [],
    },
  };

  @Watch("activePlan")
  activePlanWatcher() {
    this.setButtonText();
  }

  @Watch("seatCount")
  seatCountWatcher(value: number) {
    this.handleGetHighlightedPlan(value);
  }

  mounted() {
    this.handlePricingTable();
    this.setButtonText();
  }

  handlePricingTable() {
    console.log(this.plans);

    let pricingTable: any = {
      monthly: [],
      yearly: [],
    };

    this.plans.map((item: any) => {
      if (item.slug !== "organisation") {
        pricingTable.monthly.push({
          slug: item.slug,
          name: item.name,
          price: item.monthly.price,
          maxSeat: item.maxQuantity,
        });
        pricingTable.yearly.push({
          slug: item.slug,
          name: item.name,
          price: item.yearly.price,
          maxSeat: item.maxQuantity,
        });
      }
    });

    const orgranisation: any = this.plans.find(
      (o: any) => o.slug === "organisation"
    );

    if (orgranisation) {
      pricingTable.monthly.push({
        slug: orgranisation.slug,
        name: orgranisation.name,
        price: orgranisation.monthly.price,
        maxSeat: orgranisation.maxQuantity,
      });
      pricingTable.yearly.push({
        slug: orgranisation.slug,
        name: orgranisation.name,
        price: orgranisation.yearly.price,
        maxSeat: orgranisation.maxQuantity,
      });
    }

    this.plansStore.pricingTable = pricingTable;
  }

  handleGetHighlightedPlan(value: number) {
    console.log("handleGetHighlightedPlan");
    console.log(value);
    console.log(this.planType);
    if (value && this.planType) {
      if (this.plansStore?.pricingTable) {
        const newPlan = this.plansStore.pricingTable[this.planType].find(
          (item: Price) => this.seatCount <= item.maxSeat
        );
        if (newPlan) {
          this.highlightedPlanSlug = newPlan.slug;
        }
      }
    } else {
      this.highlightedPlanSlug = "";
    }
  }

  handleClick() {
    if (this.plan.slug === "organisation") {
      window.open("https://www.flohh.com.au/pricing/contact-sales/", "_blank");
    } else {
      this.$emit("handleUpgrade", this.plan);
    }
  }

  handleOpenBillingAddressForm() {
    this.failedModalOpen = false;
    this.billingAddressFormOpen = true;
  }

  setButtonText() {
    this.activePlanSlug = this.activePlan?.subscription?.plan.slug || "";
    let text = "";
    if (this.plan.slug !== "free" && this.activePlanSlug === "free") {
      text = `Upgrade to ${this.plan.name}`;
    } else if (this.activePlanSlug === "teacher-pro") {
      if (this.plan.slug === "free") {
        text = `Downgrade to ${this.plan.name}`;
      } else {
        text = `Upgrade to ${this.plan.name}`;
      }
    } else if (this.activePlanSlug === "teams") {
      if (this.plan.slug === "organisation") {
        text = `Upgrade to ${this.plan.name}`;
      } else {
        text = `Downgrade to ${this.plan.name}`;
      }
    } else if (
      this.plan.slug !== "organisation" &&
      this.activePlanSlug === "organisation"
    ) {
      text = `Downgrade to ${this.plan.name}`;
    }

    if (text.includes("Free")) {
      this.upgradeButtonText = text.replace("Free", "Basic");
    } else if (text.includes("Organisation")) {
      this.upgradeButtonText = "Contact Sales";
    } else {
      this.upgradeButtonText = text;
    }

    this.isUpgrade = text.includes("Upgrade");
  }

  priceFormat() {
    return this.formatPrice((this.plan[this.planType] as PlanPricing).price);
  }

  formatPrice = (price: number, withAUD = false) => {
    if (withAUD) {
      return `${this.formatCurrency(price)} AUD`;
    } else {
      return `${this.formatCurrency(price)}`;
    }
  };

  formatCurrency = (number: number, locale = "en-AU", currency = "AUD") => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  handleModalClose() {
    this.upgradePlanModalOpen = false;
  }
}
</script>

<style scoped lang="scss"></style>
