<template>
  <div class="bg-white rounded-lg m-3 p-8">
    <div class="flex justify-between items-center">
      <h6 class="text-flohh-h6 font-flohh-font-bold">Teachers</h6>
      <div class="flex gap-2">
        <AppButton type="submit" @click="handleBulkAdd">
          <template #icon_left>
            <span v-html="icon.uploadBlack"></span>
          </template>
          Bulk Add Teachers
        </AppButton>
        <AppButton
          type="submit"
          @click="handleResendActivation"
          :loading="resendingActivation"
          :disabled="resendingActivation"
        >
          <template #icon_left>
            <span v-html="icon.refreshIconBlack"></span>
          </template>
          Resend activation
        </AppButton>
        <AppButton type="submit" @click="handlePopup">
          <template #icon_left>
            <span v-html="icon.plusBlack"></span>
          </template>
          Add New Teacher
        </AppButton>
      </div>
    </div>
    <div class="py-6">
      <hr />
    </div>
    <div class="classes-data-table w-full">
      <div class="pt-5 rounded-lg overflow-hidden">
        <div class="flex justify-end mb-4 w-full gap-5">
          <FormTextInput
            type="text"
            name="search"
            v-model="searchText"
            placeholder="Search..."
            @input="handleSearch"
            :iconLeft="'searchBlack'"
            class="!w-[250px] !bg-[#fff]"
          />
          <AppButton
            v-if="data.length > 0"
            type="transparent"
            @click="handleClickDownload"
          >
            <span v-html="icon.iconDownload"></span>
          </AppButton>
        </div>
        <DataTable
          v-model:filters="filters"
          :value="filteredData"
          stateStorage="session"
          stateKey="dt-state-teacher-session"
          paginator
          showGridlines
          :rows="10"
          dataKey="uuid"
          tableStyle="min-width: 50rem;"
          filterDisplay="menu"
        >
          <Column
            field="firstName"
            header="First Name"
            sortable
            style="width: 100px"
          >
          </Column>
          <Column
            field="lastName"
            header="Last Name"
            sortable
            style="width: 100px"
          >
          </Column>
          <Column
            field="workEmail"
            header="Email"
            sortable
            style="width: 120px"
          >
          </Column>
          <Column
            field="schoolName"
            header="School"
            sortable
            style="width: 100px"
          >
          </Column>
          <Column
            field="status"
            header="Activation Status"
            style="width: 130px"
            :showFilterMatchModes="false"
            :filterMenuStyle="{ width: '16rem' }"
          >
            <template #body="{ data }">
              <span class="capitalize">{{ data.status }}</span>
            </template>
            <template #filter="{ filterModel }">
              <MultiSelect
                v-model="filterModel.value"
                :options="statusOptions"
                optionLabel="name"
                placeholder="Any"
                class="p-column-filter capitalize"
              >
                <template #option="slotProps">
                  <div class="flex align-items-center gap-2">
                    <span class="capitalize">{{ slotProps.option.name }}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
            <template #filterclear>
              <Button
                type="button"
                @click="handleClearFilter('status')"
                severity="secondary"
                >Clear</Button
              >
            </template>
          </Column>
          <Column
            field="credits.available"
            header="Submission Credits"
            sortable
            style="width: 130px"
          >
            <template #body="{ data }">
              <span class="capitalize">{{ data.credits?.available }}</span>
            </template>
          </Column>
          <Column
            field="plan.name"
            header="Plan"
            sortable
            style="width: 100px"
            :showFilterMatchModes="false"
            :filterMenuStyle="{ width: '16rem' }"
            filterField="plan.name"
          >
            <template #body="{ data }">
              <span class="capitalize">{{ data.plan?.name }}</span>
            </template>
            <template #filter="{ filterModel }">
              <MultiSelect
                v-model="filterModel.value"
                :options="planOptions"
                optionLabel="name"
                placeholder="Any"
                class="p-column-filter"
              >
                <template #option="slotProps">
                  <div class="flex align-items-center gap-2">
                    <span class="">{{ slotProps.option.name }}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
            <template #filterclear>
              <Button
                type="button"
                @click="handleClearFilter('plan')"
                severity="secondary"
                >Clear</Button
              >
            </template>
          </Column>
          <Column style="width: 60px">
            <template #body="{ data, index }">
              <div class="flex items-center justify-end">
                <button
                  class="btn-event h-[24px] w-[24px] flex items-center justify-center p-[2px] cursor-pointer opacity-80"
                  @click="
                    (event) => {
                      toggleMenu(event, index);
                    }
                  "
                >
                  <span
                    v-html="icon.ellepsisVerticalBlack"
                    class="ml-[16px] mr-[8px] flex items-center justify-center [&>svg]:!w-[auto] [&>svg]:!h-[100%]"
                  ></span>
                </button>
                <Menu
                  :model="
                    data.status === 'pending activation'
                      ? eventMenuItemsApprove
                      : eventMenuItems
                  "
                  :popup="true"
                  aria-controls="overlay_menu"
                  :ref="'menu-' + index"
                >
                  <template #item="{ item }">
                    <button
                      class="btn-event h-[42px] flex items-center justify-start cursor-pointer w-full"
                      @click="handleAction(item.event, data)"
                    >
                      <span
                        v-html="eventIcons[item.icon]"
                        class="ml-[16px] mr-[8px] flex items-center justify-center"
                        v-if="item.icon"
                      ></span>
                      <span class="text-sm pr-[16px] block">{{
                        item.label
                      }}</span>
                    </button>
                  </template>
                </Menu>
              </div>
            </template>
          </Column>
          <template #empty v-if="!isFetching"> No Teachers found. </template>
          <template #empty v-else>
            <div class="flex justify-center items-center w-full">
              <ProgressLoader /></div
          ></template>
        </DataTable>
      </div>
    </div>
  </div>
  <ImportUsersComponent v-model="openImportUserModal" />
  <SuperAdminUserModal
    v-if="isVisible"
    v-model="isVisible"
    :userData="selectedTeacher"
    :isEdit="isEdit"
    :title="title"
    :onUpdateDetails="onUpdateDetails"
    @onUpdateCredits="onUpdateCredits"
    userType="teacher"
  />
</template>

<script lang="ts">
import { ref } from "vue";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import Tag from "primevue/tag";
import Column from "primevue/column";
import Menu from "primevue/menu";
import AutoComplete from "primevue/autocomplete";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { EVENT_ICONS, TEACHER_DATA } from "./data";
import { IAssignment, IAutoCompleteCompleteEvent, IMenuRef } from "./types";
import { useToast } from "primevue/usetoast";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import { UserData } from "./types";
import ModalUtility from "../utilities/ModalUtility.vue";
import {
  IRegistrationInfo,
  TRegistrationInfo,
} from "../Authentication/Registration/types";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import TeacherService from "@/services/TeacherService";
import SuperAdminService from "@/services/SuperAdminService";
import SuperAdminUserModal from "./SuperAdminUserModal.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import ExcelJS from "exceljs";
import ImportUsersComponent from "./ImportUsersComponent.vue";
import emitter from "@/config/emitter";
import MultiSelect from "primevue/multiselect";
import { FilterMatchMode, FilterOperator } from "primevue/api";

interface IItem {
  label: string;
  value: string;
}

interface IFormData extends IErrorMessage {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
}

interface IErrorMessage {
  [key: string]: string;
}

@Component({
  components: {
    DataTable,
    Dropdown,
    Tag,
    Column,
    MultiSelect,
    InputText,
    Menu,
    AutoComplete,
    Dialog,
    Button,
    AppButton,
    ModalUtility,
    FormTextInput,
    SuperAdminUserModal,
    ProgressLoader,
    ImportUsersComponent,
  },
})
export default class SuperAdminTeacherTableComponent extends Vue {
  private teacherService: TeacherService = new TeacherService();
  private superAdminService: SuperAdminService = new SuperAdminService();
  icon = AppIcons;
  toast = useToast();
  eventBus = emitter;

  link = "https://www.google.com/";

  // data: IData[] = TEACHER_DATA;
  data: UserData[] = [];
  filteredData: UserData[] = [];
  searchText = "";

  eventIcons: Record<string, string> = EVENT_ICONS;
  visible = false;

  items: IItem[] = [{ label: `Alphabetically`, value: "alphabetically" }];
  selectedItem = ref();
  selectedTeacher = null;
  filteredItems!: IItem[];

  viewResult = "";
  isBulkUploadOpen = false;
  uploadOnly = true;
  isVisible = false;
  isEdit = false;
  title = "";
  isFetching = true;
  resendingActivation = false;
  openImportUserModal = false;

  statusOptions = [
    {
      name: "pending activation",
      slug: "pending activation",
    },
    {
      name: "active",
      slug: "active",
    },
  ];

  planOptions = [
    {
      name: "Basic",
      slug: "free",
    },
    {
      name: "Teacher Pro",
      slug: "teacher-pro",
    },
    {
      name: "Teams",
      slug: "teams",
    },
    {
      name: "Organisation",
      slug: "organisation",
    },
  ];

  eventMenuItems = [
    {
      label: "Edit",
      icon: "ICON_EDIT",
      event: "edit",
    },
    // {
    //   label: "Delete",
    //   icon: "ICON_TRASH",
    //   event: "",
    // },
  ];

  eventMenuItemsApprove = [
    {
      label: "Edit",
      icon: "ICON_EDIT",
      event: "edit",
    },
    {
      label: "Approve",
      icon: "ICON_CHECK",
      event: "approve",
    },
    {
      label: "Resend Activation",
      icon: "ICON_REFRESH",
      event: "resend_activation",
    },
    // {
    //   label: "Delete",
    //   icon: "ICON_TRASH",
    //   event: "",
    // },
  ];
  filters = ref();

  form: IRegistrationInfo = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  };

  validationMessage!: TRegistrationInfo;

  errMsg: IFormData = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
  };

  dataFilter: any = [];

  @Watch("filters")
  filtersWatcher(data: any) {
    this.handleFilter(data);
  }

  onUpdateCredits(data: any) {
    //
    const index = this.data.findIndex((o: any) => o.uuid === data.uuid);
    const credits = this.data[index].credits ?? {
      available: 0,
      total: 0,
      used: 0,
    };
    credits.available += data.numberOfCredits;
    credits.total += data.numberOfCredits;

    this.data[index].credits = credits;

    // const indexF = this.filteredData.findIndex(
    //   (o: any) => o.uuid === data.uuid
    // );
    // const creditsF = this.filteredData[index].credits ?? {
    //   available: 0,
    //   total: 0,
    //   used: 0,
    // };
    // creditsF.available += data.numberOfCredits;
    // creditsF.total += data.numberOfCredits;

    this.data[index].credits = credits;

    const filterData: any = this.handleSearchData(this.searchText);
    this.filteredData = filterData;
    // this.filteredData[indexF].credits = creditsF;

    // this.handleSearchData(this.searchText);
    this.handleFilter(this.filters);
  }

  handleClearFilter(key: any) {
    this.initFilters();

    if (key === "plan") {
      this.filters.value = {
        "plan.name": {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      };
    }

    if (key === "status") {
      this.filters.value = {
        status: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      };
    }

    this.handleFilter(this.filters);
  }

  handleFilter(data: any) {
    console.log("handleFilter");
    console.log(data);
    if (data["plan.name"]) {
      const plans = data["plan.name"].constraints[0].value;
      if (plans) {
        //
        const filterNames = plans.map((item: any) => item.slug);
        console.log("filterNames");
        console.log(filterNames);
        const searchedData: any = this.handleSearchData(this.searchText);
        let filteredData: any;
        if (filterNames) {
          this.dataFilter = [...filterNames];
          filteredData = searchedData?.filter((item: any) =>
            filterNames.includes(item.plan?.slug)
          );
        } else {
          filteredData = searchedData;
        }
        this.filteredData = filteredData;
      }
    }
    if (data.status) {
      const status = data.status.constraints[0].value;
      if (status) {
        const filterNames = status.map((item: any) => item.slug);
        const searchedData: any = this.handleSearchData(this.searchText);
        let filteredData: any;
        if (filterNames) {
          this.dataFilter = [...filterNames];
          filteredData = searchedData?.filter((item: any) =>
            filterNames.includes(item.status)
          );
        } else {
          filteredData = searchedData;
        }

        this.filteredData = filteredData;
      }
    }
  }
  async fetchTeachers() {
    this.isFetching = true;
    try {
      const response = await this.teacherService.getTeachers();
      if (response.data.ok) {
        this.data = response.data.data;
        this.filteredData = this.data;
      }
    } catch (error) {
      console.error(error);
    }
    this.isFetching = false;
  }

  async handleClickDownload() {
    try {
      const response = await this.superAdminService.downloadTeachersList();
      if (response.data.ok) {
        const data = response.data.data;
        const fileName = `Teachers List.${data.attachment.type}`;
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const a = document.createElement("a");
        a.href = `data:${fileType};base64,${data.attachment.data}`;
        a.download = fileName;
        a.click();
      }
    } catch (err) {
      console.error(err);
    }
  }

  async handleDownloadFile(book: any, fileName: string) {
    const buffer = await book.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    let blob = new Blob([buffer], {
      type: fileType,
    });
    let link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  handleBulkAdd() {
    this.openImportUserModal = true;
  }

  onUpdateDetails(uuid: string, updated: any) {
    console.log("updated");
    console.log(updated);
    let newData = [];
    newData = this.data.map((obj) => {
      if (obj.uuid === uuid) {
        return { ...obj, ...updated };
      }
      return obj;
    });

    this.data = newData;
    // this.filteredData = this.data;
    // this.filteredData = this.handleSearchData(this.searchText) || this.data;

    this.handleFilter(this.filters);
  }

  mounted() {
    this.fetchTeachers();
    this.eventBus.on("REFRESH_TEACHERS_LIST", () => {
      this.fetchTeachers();
    });

    this.initFilters();
  }

  searchItems = (event: IAutoCompleteCompleteEvent) => {
    let query = event.query;
    let _filteredItems = [];

    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];

      if (item.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }

    this.filteredItems = _filteredItems;
  };

  showToast(
    severity: "success" | "info" | "warn" | "error" | undefined,
    summary: string,
    detail: string
  ) {
    this.toast.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  }

  toggleBulkUploadPopover() {
    this.isBulkUploadOpen = !this.isBulkUploadOpen;
  }

  handlePopup() {
    this.isEdit = false;
    this.title = "Add Teacher";
    this.isVisible = !this.isVisible;
  }

  async handleResendActivation() {
    if (this.resendingActivation) return;
    this.resendingActivation = true;
    try {
      // this.showToast(
      //   "success",
      //   "Your request is being processed. It may take a few minutes.",
      //   ""
      // );
      // this.resendingActivation = false;
      const res = await this.teacherService.resendActivationAll();

      if (res.data.ok) {
        this.showToast(
          "success",
          "Your request is being processed. It may take a few minutes.",
          ""
        );
      }

      this.resendingActivation = false;
    } catch (e) {
      //
      this.resendingActivation = false;
    } finally {
      this.resendingActivation = false;
    }

    //
  }

  initFilters = () => {
    this.filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      status: {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      "plan.name": {
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    };
  };

  async handleResendActivationTeacher(uuid: string) {
    try {
      const res = await this.teacherService.resendActivation(uuid);

      if (res.data.ok) {
        this.showToast("success", "Activation sent", "");
      }
    } catch (e) {
      //
    } finally {
      //
    }
  }

  handleSave(data: any) {
    //
  }

  handleEdit(data: any) {
    this.selectedTeacher = data;
    this.isEdit = true;
    this.title = "Edit Teacher";
    this.isVisible = !this.isVisible;
  }

  handleAction(action: string, data: any) {
    //
    if (action === "edit") {
      this.handleEdit(data);
    }

    if (action === "approve") {
      this.handleApprove(data);
    }

    if (action === "resend_activation") {
      this.handleResendActivationTeacher(data.userUuid);
    }
  }

  handleDelete(data: any) {
    //
  }

  async handleApprove(data: any) {
    //
    const response = await this.teacherService.approveTeacher(data.userUuid);
    if (response.data.ok) {
      this.showToast("success", "Teacher is approved!", "");
      this.data = [];
      this.filteredData = [];
      await this.fetchTeachers();
      if (this.searchText) {
        const filterData: any = this.handleSearchData(this.searchText);
        this.filteredData = filterData;
      }
    }
  }

  onSubmit() {
    //
  }

  toggleMenu(event: Event, index: number) {
    const ref = this.$refs["menu-" + index] as IMenuRef | undefined;
    if (ref) {
      ref.toggle(event);
    }
  }

  handleSearch(event: Event) {
    const evt = event.target as HTMLInputElement;
    const filterData: any = this.handleSearchData(this.searchText);
    this.filteredData = filterData;
  }

  handleSearchData(searchText: string) {
    if (this.data && this.data && this.data.length) {
      const data = structuredClone(this.data);
      if (searchText) {
        const filterData = structuredClone(
          data.filter((o: UserData) => {
            return (
              o.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
              o.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
              o.workEmail?.toLowerCase().includes(searchText.toLowerCase()) ||
              o.schoolName?.toLowerCase().includes(searchText.toLowerCase()) ||
              o.status?.toLowerCase().includes(searchText.toLowerCase()) ||
              o.plan?.name?.toLowerCase().includes(searchText.toLowerCase())
            );
          })
        );
        return filterData;
      } else {
        const filterData = structuredClone(this.data);
        return filterData;
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$flohh-light-pink: #f4cddc;
$flohh-neutral-35: #595959;
.classes-data-table {
  .btn-event {
    margin-right: 5px;
    svg,
    img {
      height: 100%;
      width: 100%;
    }
  }
  .p-datatable-wrapper {
    border-radius: 8px;
  }
  .p-datatable {
    .p-sortable-column:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
    .p-paginator-bottom {
      background: #fff;
    }
    .p-datatable-thead {
      > tr {
        > th {
          background: $flohh-light-pink;
        }
      }
    }
    .p-datatable-tbody {
      > tr.p-highlight {
        background-color: $flohh-light-pink !important;
        color: $flohh-neutral-35 !important;
        > td {
          padding: 10px 15px;
          vertical-align: middle;
        }
      }
    }
    .p-sortable-column {
      &:not(.p-highlight) {
        &:hover {
          background: $flohh-light-pink;
        }
      }
    }
  }
  .p-datatable {
    .p-datatable-tbody {
      > tr {
        &:not(.p-highlight) {
          background-color: #f2f2f2 !important;
          &:hover {
            background: $flohh-light-pink !important;
          }
        }
      }
    }
  }
  .p-paginator {
    .p-paginator-pages {
      .p-paginator-page.p-highlight {
        background: $flohh-light-pink;
        border-color: $flohh-light-pink;
      }
    }
  }
  .p-link {
    &:focus {
      box-shadow: none !important;
    }
  }
  .p-autocomplete-dd .p-autocomplete-input {
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    border-right-width: 0 !important;
    font-size: 14px;
  }
  .p-autocomplete-dd .p-autocomplete-dropdown {
    background-color: #fff;
    color: #333;
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    border-left-width: 0 !important;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  th.p-sortable-column:focus {
    outline: 0 !important;
    box-shadow: 0 !important;
  }
}
.p-menu {
  width: auto !important;
  padding: 0 !important;
}

.p-column-filter-buttonbar .p-button {
  color: #555 !important;
  background-color: #a3d7be !important;
  border-color: #a3d7be !important;
}
</style>
