<script lang="ts">
import FormField from "@/components/Layout/Forms/FormField.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import { Vue, Component, Prop, Watch } from "vue-facing-decorator";
import { AssignmentSubmissionSummaryStudent } from "@/store/assignment/assignmentTypes";

type Error = {
  email?: string;
  firstName?: string;
  lastName?: string;
};

@Component({
  components: { FormField, AppTypographyText },
})
export default class StudentItem extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  index!: number;

  @Prop({
    type: Object,
    required: false,
  })
  student!: AssignmentSubmissionSummaryStudent;

  @Prop({
    type: Object,
  })
  error!: Error;

  @Prop({
    type: Number,
    required: true,
  })
  studentCount!: number;

  studentPayload = {
    firstName: "",
    lastName: "",
    email: "",
  };

  firstName = "";
  lastName = "";
  email = "";

  @Watch("student")
  studentWatcher(student: AssignmentSubmissionSummaryStudent) {
    this.setData(student);
  }

  mounted() {
    this.setData(this.student);
  }

  setData(student: AssignmentSubmissionSummaryStudent) {
    const studentProfile = student.profile;
    this.firstName = studentProfile.firstName;
    this.lastName = studentProfile.lastName;
    this.email = studentProfile.email || "";

    this.studentPayload = {
      firstName: studentProfile.firstName,
      lastName: studentProfile.lastName,
      email: studentProfile.email || "",
    };
  }

  @Watch("firstName")
  firstNameWatcher(value: string) {
    const student = {
      ...this.student,
      profile: {
        ...this.student.profile,
        firstName: value,
      },
    };

    this.$emit("onStudentUpdate", { index: this.index, student });
  }

  @Watch("lastName")
  lastNameWatcher(value: string) {
    const student = {
      ...this.student,
      profile: {
        ...this.student.profile,
        lastName: value,
      },
    };

    this.$emit("onStudentUpdate", { index: this.index, student });
  }

  @Watch("email")
  emailWatcher(value: string) {
    const student = {
      ...this.student,
      profile: {
        ...this.student.profile,
        email: value,
      },
    };

    this.$emit("onStudentUpdate", { index: this.index, student });
  }

  handleClickRemoveStudent() {
    this.$emit("onRemoveStudent", this.index);
  }
}
</script>
<template>
  <div class="flex flex-row mb-2 gap-x-4">
    <div class="flex-1">
      <AppTypographyText
        v-if="index === 0"
        label="First Name"
        variant="md"
        type="body"
        class="mb-2"
      />
      <FormField
        type="text"
        v-model="firstName"
        :error="error && error.firstName ? error.firstName : ''"
        placeholder="Jessica"
        required
      ></FormField>
    </div>
    <div class="flex-1">
      <AppTypographyText
        v-if="index === 0"
        label="Last Name"
        variant="md"
        type="body"
        class="mb-2"
      />
      <FormField
        type="text"
        v-model="lastName"
        :error="error && error.lastName ? error.lastName : ''"
        placeholder="Jones"
        required
      ></FormField>
    </div>
    <div class="flex-1">
      <AppTypographyText
        v-if="index === 0"
        label="Email"
        variant="md"
        type="body"
        class="mb-2"
      />
      <FormField
        type="text"
        v-model="email"
        :error="error && error.email ? error.email : ''"
        placeholder="Jessica@school.com"
        required
      ></FormField>
    </div>
    <div class="flex items-center justify-center px-1">
      <i
        v-if="studentCount === 1"
        :class="index === 0 ? 'translate-y-2/4' : ''"
        class="text-lg rounded-full cursor-not-allowed pi pi-times-circle"
      ></i>
      <i
        v-else
        @click="handleClickRemoveStudent"
        :class="index === 0 ? 'translate-y-2/4' : ''"
        class="text-lg rounded-full cursor-pointer pi pi-times-circle"
      ></i>
    </div>
  </div>
</template>
