<template>
  <ModalUtility
    v-model="isModalOpen"
    title="You’ve been invited to join a school"
  >
    <template #header>
      <div
        class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
      >
        <div
          class="absolute top-50 right-50 px-4 flex flex-col items-start justify-center w-full"
        >
          <h1 class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20">
            You’ve been invited to join a school
          </h1>

          <!-- Use for teleportation don't remove this tag -->
          <p id="modal-subtitle-teleport" class="font-semibold"></p>
          <!-- end of teleportation -->
        </div>

        <div class="btn-close-wrap absolute top-[10px] right-[10px]">
          <button
            class="p-2 text-white rounded-full"
            @click="closeModal(false)"
          >
            <img src="@/assets/close.svg" class="w-4" />
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div v-if="inviteInfo" class="flex flex-col gap-6 p-6">
        <p class="text-flohh-text-title font-flohh-font-bold">
          {{ inviteInfo.description }}
        </p>
        <AppButton
          label="Accept Invitation"
          type="submit"
          @click="() => handleInvitation('accept')"
          :disabled="isAccepting || isRejecting"
          :loading="isAccepting"
        />
        <p class="text-flohh-text-small font-flohh-font-medium">
          By Accepting this invitation, you agree with
          <a :href="termsURL" class="underline" target="_blank"
            >Flohh’s Terms and Conditions</a
          >
          and
          <a :href="termsURL" class="underline" target="_blank"
            >Privacy Policy</a
          >
        </p>
        <p class="text-flohh-text-small font-flohh-font-medium">
          Don’t want to join this school?
          <button
            class="underline cursor-pointer"
            @click="() => handleInvitation('decline')"
            :disabled="isRejecting"
          >
            Reject Invitation
          </button>
        </p>
      </div>
    </template></ModalUtility
  >
</template>

<script lang="ts">
import { Component, Prop, Vue, Model } from "vue-facing-decorator";
import { PendingActions } from "@/store/dashboard/dashboardTypes";
import AccountsPortalService from "@/services/AccountsPortalService";
import { useToast } from "primevue/usetoast";

import ModalUtility from "@/components/utilities/ModalUtility.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { AxiosResponse } from "axios";

@Component({
  components: { ModalUtility, AppButton },
})
export default class SubscriptionInvitationModal extends Vue {
  private accountsPortalService: AccountsPortalService =
    new AccountsPortalService();

  toast = useToast();

  @Model({
    type: Boolean,
    default: false,
    required: true,
  })
  isModalOpen!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  inviteInfo!: PendingActions;

  termsURL = `${process.env.PUBLIC_SITE_URL}/terms`;
  privacyURL = `${process.env.PUBLIC_SITE_URL}/privacy`;

  isAccepting = false;
  isRejecting = false;

  async handleInvitation(action: "accept" | "decline") {
    try {
      this.isAccepting = true;
      this.isRejecting = true;

      const response: AxiosResponse =
        await this.accountsPortalService.updateInvitation(
          this.inviteInfo.uuid as string,
          action
        );

      if (response.data.ok) {
        this.removeInvitationFromSession();
        this.openSuccessToast(action);
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.closeModal(true);
      this.isAccepting = false;
      this.isRejecting = false;
    }
  }

  removeInvitationFromSession() {
    const userSessionLocalSorage = localStorage.getItem("userSession");
    if (userSessionLocalSorage) {
      const userSession = JSON.parse(userSessionLocalSorage);
      const updatedUserSession = userSession.filter(
        (item: PendingActions) => item.type !== "subscriptionTeacherInvite"
      );
      localStorage.setItem("userSession", JSON.stringify(updatedUserSession));
    }
  }

  openSuccessToast(action: "accept" | "decline") {
    if (action === "accept") {
      this.toast.add({
        severity: "success",
        summary: "Success!",
        detail: "You've successfully joined",
        life: 3000,
      });
    } else {
      this.toast.add({
        severity: "success",
        summary: "Success",
        detail: "Invitation rejected",
        life: 3000,
      });
    }
  }

  closeModal(isRefresh: boolean) {
    this.$emit("onClose", isRefresh);
  }
}
</script>

<style scoped lang="scss"></style>
