<template>
  <div class="relative flex flex-col gap-5">
    <ClassesTabHeaderComponent
      v-model="selectedAssignmentFilter"
      :assignments="assignments"
      :selectedAssignment="selectedAssignment"
      :loadingAssignments="loadingAssignments"
      :loadingSummary="loading"
      :assignmentSummary="assignmentSummary"
      :hasMarkedStatus="hasMarkedStatus"
      tabType="assignment"
      @onSelectAssignment="handleSelectAssignment"
    />
    <div class="w-full h-full p-5 rounded-lg bordered assignment-section">
      <div
        v-if="loading && !loadingAssignments"
        class="flex flex-col justify-center items-center gap-2 bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full"
      >
        <ProgressLoader
          label="Loading students"
          labelVariant="md"
          labelType="subtitle"
        />
      </div>
      <ClassesTableComponent
        v-else-if="!loading && assignments.length"
        :classUuid="classUuid"
        :data="students"
        :assignment="selectedAssignment"
        :assignmentSummary="assignmentSummary"
        :tabType="'assignment'"
        :assignmentPdfBase64="assignmentPdfBase64"
        :totalSubmissionsDone="totalSubmissionsDone"
        :totalMarkedAssignments="totalMarkedAssignments"
        :submissionProgressBar="submissionProgressBar"
        :sortDefault="sortDefault"
        :isFull="true"
        :hideHeader="false"
        @onSort="handleSort"
        @onSearch="handleSearch"
      >
        <div class="flex items-center h-[3em] pl-4 checkbox-head">
          <div class="select-all">
            <Checkbox
              v-model="selectedAllStudent"
              :disabled="assignmentSummaryData.submissionsMade === 0"
              :binary="true"
              @click="handleSelectAllStudent(assignmentSummaryData.students)"
              v-tooltip.top="{
                value: selectedAllStudent ? 'Deselect All' : 'Select All',
                showDelay: 500,
              }"
            ></Checkbox>
            <AppDropdownButton
              label="Bulk Actions"
              :disabled="selectedStudents.length === 0"
              size="sm"
              @clickMenu="handleTableBulkAction"
              :menus="tableBulkActions"
              type="default"
              width="w-60"
              dropdownIcon="arrowDropDownBlack"
              :toRight="true"
              v-tooltip.top="{ value: 'Bulk Actions', showDelay: 500 }"
            />
          </div>
        </div>
        <Column frozen :style="{ width: '54px', maxWidth: '54px' }">
          <template #body="{ data }">
            <Checkbox
              v-if="
                data.submission &&
                hasSubmissionStatusArr.includes(data.submission.status) &&
                selectedAssignment.visible
              "
              :modelValue="isRowChecked(data)"
              :binary="true"
              :inputId="data.id"
              :value="data.id"
              @click="handleStudentSelect(data)"
            ></Checkbox>
          </template>
        </Column>
        <Column :style="{ maxWidth: '100px', minWidth: '50px' }" frozen>
          <template #header
            ><div class="assignment-custom-cell">Student Name</div></template
          >
          <template #body="props">
            <div class="assignment-custom-cell">
              <CustomTooltip :tooltip="props.data.student.email"
                ><template #body>{{
                  `
                ${props.data.student.profile.firstName} ${props.data.student.profile.lastName}`
                }}</template></CustomTooltip
              >
            </div>
          </template>
        </Column>
        <Column
          field="submittedDate"
          :style="{ maxWidth: '20px', minWidth: '20px', textAlign: 'center' }"
        >
          <template #header><div class="mx-auto">Date Submitted</div></template>
          <template #body="{ data }">
            {{
              data.submission && data.submission.submittedAt
                ? formatDate(data.submission.submittedAt)
                : "N/A"
            }}
          </template>
        </Column>
        <Column
          field="submission.status"
          :style="{
            maxWidth: '50px',
            minWidth: '50px',
            textAlign: 'center',
            lineHeight: 1.1,
          }"
        >
          <template #header><div class="mx-auto">Marking Status</div></template>
          <template #body="{ data }">
            <div v-if="!selectedAssignment.visible" class="flex justify-center">
              <!-- visible -->
              <div class="px-4 py-2 rounded-lg bg-flohh-neutral-85 w-max">
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Unassigned
                </span>
              </div>
            </div>
            <div v-else class="flex justify-center">
              <!-- assigned -->
              <div
                v-if="!data.submission"
                class="px-4 py-2 rounded-lg bg-flohh-secondary-dark-blue w-max"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Assigned
                </span>
              </div>

              <!-- unmarked -->
              <div
                v-if="data.submission && data.submission.status == 'unmarked'"
                class="px-4 py-2 rounded-lg bg-flohh-secondary-purple w-max"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Submitted
                </span>
              </div>

              <!-- marked -->
              <div
                v-if="data.submission && data.submission.status == 'marked'"
                class="px-4 py-2 rounded-lg bg-flohh-secondary-pink w-max"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Marked
                </span>
              </div>

              <!-- making in progress -->
              <div
                v-if="
                  data.submission &&
                  data.submission.status == 'marking in progress'
                "
                class="px-4 py-2 rounded-lg bg-flohh-secondary-yellow w-max"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Marking In Progress
                </span>
              </div>

              <!-- requested -->
              <div
                v-if="
                  data.submission &&
                  data.submission.status == 'learning goal requested'
                "
                class="px-4 py-2 rounded-lg bg-flohh-secondary-blue w-max"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Learning Goal Requested</span
                >
              </div>

              <!-- student submitted the learning goal -->
              <div
                v-if="
                  data.submission &&
                  data.submission.status == 'learning goal submitted'
                "
                class="px-4 py-2 rounded-lg bg-flohh-secondary-orange w-max"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Learning Goal Submitted
                </span>
              </div>

              <!-- completed -->
              <div
                v-if="data.submission && data.submission.status == 'completed'"
                class="px-4 py-2 rounded-lg bg-flohh-secondary-green w-max"
              >
                <span class="text-flohh-text-small font-flohh-font-medium">
                  Completed
                </span>
              </div>
            </div>
          </template>
        </Column>
        <Column
          field="submission.markingTime.value"
          :style="{ maxWidth: '20px', minWidth: '20px', textAlign: 'center' }"
        >
          <template #header><div class="mx-auto">Marking Time</div></template>
          <template #body="props">
            {{ formatSeconds(props.data.submission) }}
          </template> </Column
        ><Column
          :style="{
            width: '55px',
            textAlign: 'center',
            position: 'relative',
          }"
          ><template #header>
            <div
              class="absolute next-actions"
              v-tooltip.top="{ value: 'Next Action', showDelay: 500 }"
            >
              <span v-html="icons.pencilBlack"></span></div
          ></template>
          <template #body="{ data }">
            <div
              v-if="!selectedAssignment.visible"
              class="absolute cursor-pointer next-actions"
            >
              <span
                v-tooltip.top="{
                  value: 'Assign To Class',
                  showDelay: 500,
                }"
                @click="handleMakeAssignmentVisible"
                v-html="icons.iconAssignment"
              ></span>
            </div>
            <div v-else class="absolute cursor-pointer next-actions">
              <span
                v-if="
                  data.submission &&
                  (data.submission.status == 'unmarked' ||
                    data.submission.status === 'marking in progress')
                "
                @click="handleStartMarking(data)"
                v-tooltip.top="{
                  value: 'Mark Submission',
                  showDelay: 500,
                }"
                v-html="icons.pencilBlack"
              ></span>
              <span
                v-else-if="
                  data.submission && data.submission.status === 'marked'
                "
                v-tooltip.top="{
                  value: 'Return Feedback',
                  showDelay: 500,
                }"
                @click="handleReturnFeedbackPerSubmission(data)"
                v-html="eventIcons['ICON_EMAIL']"
              ></span>
              <span
                v-else-if="
                  data.submission &&
                  data.submission.status === 'learning goal submitted'
                "
                v-tooltip.top="{
                  value: 'Review Learning Goal',
                  showDelay: 500,
                }"
                @click="redirectToLearningGoalsTab()"
                v-html="icons.eyeActive"
              ></span>
              <span
                v-else-if="
                  data.submission &&
                  (data.submission.status == 'completed' ||
                    data.submission.status === 'learning goal requested')
                "
                v-tooltip.top="{
                  value: 'View Submission',
                  showDelay: 500,
                }"
                @click="handleViewSubmission(data)"
                v-html="icons.eyeActive"
              ></span>
              <span
                v-else
                v-tooltip.top="{
                  value: 'Upload Submission',
                  showDelay: 500,
                }"
                @click="handleUploadAssignment(data)"
                v-html="eventIcons['ICON_UPLOAD']"
              ></span>
            </div>
          </template>
        </Column>
        <Column style="width: 55px; position: relative; padding: 0px">
          <template #header
            ><div class="actions-cell bg-flohh-secondary-orange">
              <div
                class="action-icon-container"
                v-tooltip.top="{ value: 'More Options', showDelay: 500 }"
              >
                <span v-html="icons.menuBarSmallBlack"></span>
              </div></div
          ></template>
          <template #body="{ data }">
            <div
              class="flex items-center justify-center actions-cell actions-body"
              v-tooltip.top="{
                value: 'More Options',
                showDelay: 500,
              }"
            >
              <AppDropdownButton
                size="sm"
                @clickMenu="(action: string) => {
                handleClickMenu(action, data)
              }"
                :menus="moreOptions[handleFilterData(data)]"
                type="default"
                text
                width="w-40"
                dropdownIcon="ellepsisVerticalBlack"
                :menuOffsetTop="20"
              />
            </div>
          </template>
        </Column>
        <template #empty> No Students found. </template>
      </ClassesTableComponent>

      <div
        v-if="!loading && assignments.length === 0"
        class="flex justify-center flex-col items-center bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full space-y-4 mt-5"
      >
        <AppTypographyText
          variant="bd"
          type="title"
          label="Welcome to your new class!"
        />
        <AppTypographyText
          variant="md"
          type="subtitle"
          label="Get started by creating an assignment or jump straight to uploading some submissions to mark."
        />
        <div class="flex items-center justify-center gap-10">
          <AppButton type="submit" @click="handleAddAssignment">
            <span v-html="icons.plusBlack" class="mr-2"></span>
            Assignment
          </AppButton>
          <AppButton type="submit" @click="toggleBulkUpload">
            <template #icon_left>
              <span v-html="icons.uploadBlack"></span>
              Submissions
            </template>
          </AppButton>
        </div>
      </div>
      <div
        v-else-if="loadingAssignments"
        class="flex flex-col justify-center items-center bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full animate-pulse mt-5"
      >
        <ProgressLoader
          label="Loading Assignments"
          labelVariant="md"
          labelType="subtitle"
        />
      </div>
    </div>

    <Dialog
      :visible="visible"
      modal
      :style="{ width: '410px' }"
      :pt="{
        header: { class: '!py-4 !pl-6' },
        content: { class: '!p-0' },
        footer: { class: '!p-0' },
      }"
    >
      <template #header>
        <div class="">
          <h5
            class="flex items-center justify-start font-bold text-default text-neutral-gray-500"
          >
            <i v-html="eventIcons['ICON_TRASH']" class="mr-2"></i>Remove Student
            from Assignment
          </h5>
        </div>
      </template>
      <div class="px-6 py-4 border-t border-solid border-neutral-gray-500">
        <p class="text-sm text-neutral-gray-500">
          You are about to delete this student from the assignment. Any
          submissions submitted by this student will also be deleted. Are you
          sure you want to remove this student from the assignment?
        </p>
        <br />
        <p class="text-sm font-bold text-neutral-gray-500">
          {{ selectedDeleteStudent.profile.firstName }}
          {{ selectedDeleteStudent.profile.lastName }},
          {{ selectedDeleteStudent.profile.email }}
        </p>
      </div>
      <template #footer>
        <div class="flex items-center justify-end px-6 py-2">
          <Button
            label="Cancel"
            @click="handleHideModal"
            class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
          />
          <Button
            label="Remove Student"
            @click="handleDelete"
            class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
            :disabled="loadingDeleteStudent"
            :loading="loadingDeleteStudent"
            :class="[loadingDeleteStudent ? 'cursor-progress' : '']"
          />
        </div>
      </template> </Dialog
    ><Dialog
      :visible="bulkDeleteVisible"
      modal
      :closable="false"
      :close-on-escape="true"
      :style="{ width: '500px' }"
      :pt="{
        header: { class: '!py-4 !pl-6' },
        content: { class: '!p-0' },
        footer: { class: '!p-0' },
      }"
    >
      <template #header>
        <div class="">
          <h5
            class="flex items-center justify-start font-bold text-default text-neutral-gray-500"
          >
            <i v-html="eventIcons['ICON_TRASH']" class="mr-2"></i>Are you sure
            you want to delete this submission file?
          </h5>
        </div>
      </template>
      <div class="px-6 py-4 border-t border-solid border-neutral-gray-500">
        <p class="text-sm text-neutral-gray-500">
          The following students' submissions file and any data, grades,
          comments associated with the submission will also be deleted.
        </p>
        <br />
        <p
          v-for="(item, index) in selectedStudents"
          :key="index"
          class="text-sm font-bold text-neutral-gray-500"
        >
          {{
            !item.student.email
              ? "• No Assigned Student"
              : `• ${item.student.profile.firstName} ${item.student.profile.lastName} (${item.student.email})`
          }}
        </p>
      </div>
      <template #footer>
        <div class="flex items-center justify-end px-6 py-2">
          <Button
            label="Cancel"
            @click="bulkDeleteVisible = false"
            class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
          />
          <Button
            label="Remove Submissions"
            @click="handleBulkDelete"
            class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
            :disabled="isSubmissionsDelete"
            :loading="isSubmissionsDelete"
            :class="[isSubmissionsDelete ? 'cursor-progress' : '']"
          />
        </div>
      </template>
    </Dialog>
  </div>
  <AssignmentSubmission
    v-model="openSubmitStudent"
    title="Upload Student Submission"
  />

  <ModalUtility
    v-model="show"
    :width="'1200px'"
    @onClose="resetBase64('submission')"
  >
    <template #header="{ handleClickClose }">
      <ViewAssignmentHeader
        :handleClickClose="handleClickClose"
        :infos="infos"
        :isStudentView="false"
      />
    </template>

    <template #content>
      <!-- this is for viewing students uploaded assignments so assignment type will always be 'document'. -->
      <ViewAssignmentComponent
        viewType="teacher"
        assignmentType="document"
        :assignmentStatus="infos.assignmentStatus"
        :assignment="selectedAssignment"
        :errorMsg="errorMsg"
        :pdfBase64="submissionPdfBase64"
        :comment="infos.comment"
        :learningGoal="infos.learningGoal"
        :submission="infos.submission"
        :submissionId="infos.submissionId"
        :studentId="infos.studentId"
        classUuid=""
      />
    </template>
  </ModalUtility>

  <ModalUtility v-model="isViewAssignment" :title="title">
    <template #content>
      <div
        v-if="selectedAssignment.assignmentType === 'document'"
        class="h-[77vh]"
      >
        <div v-if="assignmentPdfBase64" class="h-full">
          <PdfViewComponent :pdfBase64="assignmentPdfBase64" />
        </div>
        <LoadingState v-else state="Loading document" backdrop="95" />
      </div>
      <div
        v-else
        v-html="selectedAssignment.component.content"
        class="p-5"
      ></div>
    </template>
  </ModalUtility>

  <Dialog
    :visible="visibilityModalUpdateOpen"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ maxWidth: '545px', width: '100%' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="flex items-center justify-start font-bold text-default text-neutral-gray-500"
        >
          <i v-html="eventIcons['ICON_EDIT']" class="mr-2"></i>Update Assignment
          Visibility
        </h5>
      </div>
    </template>
    <div class="px-6 py-4 border-t border-solid border-neutral-gray-500">
      <p class="text-sm text-neutral-gray-500">
        It looks like this assignment isn't visible to students.
      </p>
      <br />
      <p class="text-sm font-bold text-neutral-gray-500">
        Would you like to update the assignment to make it visible to students?
      </p>
    </div>
    <template #footer>
      <div class="flex items-center justify-end px-6 py-2">
        <Button
          label="No"
          @click="visibilityModalUpdateOpen = false"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Yes"
          @click="handleMakeAssignmentVisible"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="loadingUpdateAssignmentVisibility"
          :loading="loadingUpdateAssignmentVisibility"
          :class="[loadingUpdateAssignmentVisibility ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="showDeleteSubmission"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ maxWidth: '520px', width: '100%' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="flex items-center justify-start font-bold text-default text-neutral-gray-500"
        >
          <i v-html="eventIcons['ICON_TRASH']" class="mr-2"></i>Are you sure you
          want to delete this submission file?
        </h5>
      </div>
    </template>
    <div class="px-6 py-4 border-t border-solid border-neutral-gray-500">
      <p class="text-sm text-neutral-gray-500">
        The submission file and any data, grades, comments associated with the
        submission will also be deleted.
      </p>
    </div>
    <template #footer>
      <div class="flex items-center justify-end px-6 py-2">
        <Button
          label="Cancel"
          @click="handleHideSubmissionModal"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Remove Submission"
          @click="handleDeleteSubmission"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="isSubmissionDelete"
          :loading="isSubmissionDelete"
          :class="[isSubmissionDelete ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>

  <AddEmailForStudentModal
    v-if="studentWithoutEmail && studentWithoutEmail.length > 0"
    v-model="addEmailModalOpen"
    :studentData="studentWithoutEmail"
    :classUuid="classUuid"
  />

  <UploadSubmissionModal
    v-model="isBulkUploadOpen"
    :defaultClass="classUuid"
    :checkStatus="false"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Column from "primevue/column";
import Menu from "primevue/menu";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ClassesTabHeaderComponent from "./ClassesTabHeaderComponent.vue";
import ClassesTableComponent from "./ClassesTableComponent.vue";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import { IMenuRef, IInfos } from "./types";
import {
  setAssignment,
  setAssignmentSubmissionSummary,
} from "@/store/assignment/assignment-dispatch";
import {
  AssigmentSubmissionSummaryData,
  AssignmentData,
  AssignmentSubmissionSummaryStudent,
  AssignmentSubmissionSummaryStudents,
} from "@/store/assignment/assignmentTypes";
import { EVENT_ICONS, MORE_OPTIONS } from "./data";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import ClassAssignmentStudentsTable from "./data/ClassAssignmentStudentsTable.vue";
import { setSelectedStudentSummary } from "@/store/assignment/assignment-dispatch";
import AssignmentSubmission from "../Assignment/Submission/AssignmentSubmission.vue";
import emitter from "@/config/emitter";
import { formatDate as DateFormatter, formatDate } from "@/utils/formatter";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import ViewAssignmentHeader from "../ViewAssignment/ViewAssignmentHeader.vue";
import ViewAssignmentComponent from "../ViewAssignment/ViewAssignmentComponent.vue";
import axios, { AxiosResponse } from "axios";
import AssignmentService from "@/services/AssignmentService";
import { useToast } from "primevue/usetoast";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment.vue";
import { deleteStudent } from "@/store/student/student-dispatch";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import { StorageDataKey } from "@/enums/enums";
import { sortAssignmentTable } from "@/utils/sort";
import MediaService from "@/services/MediaService";
import PdfViewComponent from "@/components/BulkUpload/PdfViewComponent.vue";
import LearningGoalService from "@/services/LearningGoalService";
import LoadingState from "../utilities/LoadingState.vue";
import CustomTooltip from "../utilities/CustomTooltip.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import Checkbox from "primevue/checkbox";
import AddEmailForStudentModal from "./utilities/AddEmailForStudentModal.vue";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import SubmissionService from "@/services/SubmissionService";
import UploadSubmissionModal from "@/components/utilities/UploadSubmissionModal.vue";

@Component({
  components: {
    ClassesTabHeaderComponent,
    ClassesTableComponent,
    FormTextInput,
    Column,
    Menu,
    Dialog,
    Button,
    AppTypographyText,
    ClassAssignmentStudentsTable,
    AssignmentSubmission,
    ModalUtility,
    ViewAssignmentHeader,
    ViewAssignmentComponent,
    AppButton,
    CreateAssignment,
    FormInputDropdown,
    PdfViewComponent,
    LoadingState,
    CustomTooltip,
    ProgressLoader,
    Checkbox,
    AddEmailForStudentModal,
    AppDropdownButton,
    UploadSubmissionModal,
  },
})
export default class ClassesAssignmentsComponent extends Vue {
  toast = useToast();
  private assignmentService = new AssignmentService();
  private mediaService = new MediaService();
  private learningGoalService = new LearningGoalService();
  private submissionService = new SubmissionService();

  @Prop({
    type: [String, Number],
    required: true,
  })
  classUuid!: string | number;

  @Prop({
    default: [],
    required: true,
  })
  assignments!: AssignmentData[];

  @Prop({
    default: {},
    required: true,
  })
  selectedAssignmentData!: AssignmentData;

  @Prop({
    default: {},
    required: true,
  })
  assignmentSummaryData!: AssigmentSubmissionSummaryData;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingAssignments!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingSummary!: boolean;

  destroyed = false;
  selectedAssignmentFilter = "";
  hasMarkedStatus = false;

  submissionProgressBar = "0%";
  totalSubmissionsDone = 0;
  totalMarkedAssignments = 0;
  students!: AssignmentSubmissionSummaryStudents[];
  hoveredIndex = -1;
  loading = true;
  selectedAssignment!: AssignmentData;
  assignmentSummary!: AssigmentSubmissionSummaryData;

  eventBus = emitter;
  eventIcons: Record<string, string> = EVENT_ICONS;
  icons = AppIcons;
  sortDefault = {
    sortBy: "last_alphabetical",
    sortData: [
      { label: "Last Name: Alphabetical", value: "last_alphabetical" },
      { label: "Date Submitted: Recent", value: "date_recent" },
      { label: "Marking Status", value: "marking_status" },
      { label: "Marking Time: Fastest", value: "marking_time_fastest" },
    ],
  };
  visibilityModalUpdateOpen = false;
  loadingUpdateAssignmentVisibility = false;

  visible = false;
  selectedDeleteStudent!: AssignmentSubmissionSummaryStudent;
  loadingDeleteStudent = false;
  isSubmissionDelete = false;
  showDeleteSubmission = false;
  selectedSubmission!: AssignmentSubmissionSummaryStudents;
  openSubmitStudent = false;
  assignmentPdfBase64 = "";
  submissionPdfBase64 = "";
  show = false;
  errorMsg = "";
  infos!: IInfos;
  isViewAssignment = false;
  title = "";
  isBulkUploadOpen = false;

  // For Checkbox
  selectedStudents: AssignmentSubmissionSummaryStudents[] = [];
  selectedStudent = false;
  selectedAllStudent = false;

  hasSubmissionStatusArr = [
    "unmarked",
    "marked",
    "marking in progress",
    "learning goal requested",
    "learning goal submitted",
    "completed",
  ];

  addEmailModalOpen = false;
  studentWithoutEmail!: AssignmentSubmissionSummaryStudent[];

  tableBulkActions = [
    { icon: "iconDownload", name: "Download Submission" },
    {
      icon: "trashSmallDarkRedIcon",
      name: "Delete Submission",
    },
  ];
  bulkDeleteVisible = false;
  isSubmissionsDelete = false;

  moreOptions = MORE_OPTIONS;

  beforeUnmount() {
    this.destroyed = true;
  }

  @Watch("selectedAssignmentData")
  selectedAssignmentWatcher(value: AssignmentData) {
    if (value) {
      this.selectedAssignmentFilter = value.uuid;
      this.selectedAssignment = value;
      if (!this.assignmentPdfBase64 && !this.submissionPdfBase64) {
        this.handleGetPDFBase64(
          this.selectedAssignment.component.media,
          "assignment"
        );
      }
    }
  }

  @Watch("assignmentSummaryData")
  assignmentSummaryWatcher(value: AssigmentSubmissionSummaryData) {
    this.assignmentSummary = value;
    this.students = value.students;
    this.handleHasMarkedStatusCheck();
    this.handleSubmissionCount();

    // For Demo Assignments
    if (value) {
      const i = value.students.findIndex(
        (student: any) =>
          student.student.profile.firstName === "Demo First Name" &&
          student.student.profile.lastName === "Demo Last Name"
      );
      const cloneData = value.students;
      if (i >= 0 && cloneData.length > 0) {
        cloneData[i].student.profile.firstName = "On right";
        cloneData[i].student.profile.lastName = "Click three dots";
        cloneData[i].student.profile.email = "flohhstudent-demo@example.com";
      }
      this.students = cloneData;
    }
  }

  @Watch("loadingSummary")
  loadingSummaryWatcher(value: boolean) {
    this.loading = value;
    if (!value) {
      const assignmentQuery = this.$route.query.assignment;
      const assignmentUuidQuery = assignmentQuery
        ? assignmentQuery.toString()
        : "";
      const tabQuery = this.$route.query.tab;
      const tab = tabQuery ? tabQuery.toString() : "";

      if (assignmentUuidQuery === "all" && tab === "assignments") {
        this.handleAddAssignmentQuery(this.selectedAssignmentData);
      }
    }
  }

  @Watch("loadingAssignments")
  loadingAssignmentsWatcher(value: boolean) {
    if (!value && this.assignments.length === 0) {
      this.selectedAssignmentFilter = "none";
    }
  }

  resetBase64(mediaType: "submission" | "assignment") {
    if (mediaType === "submission") {
      this.submissionPdfBase64 = "";
    } else {
      this.assignmentPdfBase64 = "";
    }
    this.errorMsg = "";
  }

  async handleClickViewAssignment() {
    this.isViewAssignment = !this.isViewAssignment;
    const selectedAssignmentData = this.selectedAssignment;
    this.title = `${selectedAssignmentData.title} - ${selectedAssignmentData.class.code} - ${selectedAssignmentData.class.subject}`;
  }

  handleFilterData(data: AssignmentSubmissionSummaryStudents): number {
    if (!this.selectedAssignment.visible) {
      return 7;
    }
    if (data.submission?.status === "unmarked") {
      return 1;
    }

    if (data.submission?.status === "marking in progress") {
      return 2;
    }

    if (data.submission?.status === "marked") {
      return 3;
    }

    if (data.submission?.status === "learning goal submitted") {
      return 4;
    }

    if (data.submission?.status === "learning goal requested") {
      return 5;
    }

    if (data.submission?.status === "completed") {
      return 6;
    }

    return 0;
  }

  handleClickMenu(item: string, data: AssignmentSubmissionSummaryStudents) {
    switch (item) {
      case "Assign To Class": // assign_to_class
        this.eventBus.emit("EVENT_TRIGGER", "CP021");
        this.handleMakeAssignmentVisible();
        break;

      case "Upload Submission": // submit_assignment
        this.eventBus.emit("EVENT_TRIGGER", "CP008");
        this.handleUploadAssignment(data);
        break;

      case "Mark Submission": // start_marking
        this.eventBus.emit("EVENT_TRIGGER", "CP012");
        this.handleStartMarking(data);
        break;

      // case "edit_marking": // edit_marking
      //   this.eventBus.emit("EVENT_TRIGGER", "CP011");
      //   this.handleStartMarking(data);
      //   break;

      case "View Submission": // view_submission
        this.eventBus.emit("EVENT_TRIGGER", "CP009");
        this.handleViewSubmission(data);
        break;

      case "Review Learning Goal": // review_learning_goal
        this.eventBus.emit("EVENT_TRIGGER", "CP020");
        this.redirectToLearningGoalsTab();
        break;

      case "return_submission": // return_submission
        this.eventBus.emit("EVENT_TRIGGER", "CP014");
        this.eventBus.emit("SHOW_LOADING", {
          showLoader: true,
          message: "Processing",
        });
        this.handleReturnSubmission(data);
        break;

      case "Return Feedback": // return_feedback
        this.eventBus.emit("EVENT_TRIGGER", "CP014");
        this.eventBus.emit("SHOW_LOADING", {
          showLoader: true,
          message: "Processing",
        });
        this.handleReturnFeedbackPerSubmission(data);
        break;

      case "Approve Learning Goal": // approve_learning_goal
        this.eventBus.emit("EVENT_TRIGGER", "CP013");
        this.eventBus.emit("SHOW_LOADING", {
          showLoader: true,
          message: "Processing",
        });
        this.handleApproveLearningGoal(data);
        break;

      case "Download Submission": // download_submission
        this.eventBus.emit("EVENT_TRIGGER", "CP010");
        this.handleDownloadSubmission(data);
        break;

      case "Delete Submission": // delete_submission
        this.showDeleteSubmission = true;
        this.selectedSubmission = data;
        break;

      default:
        break;
    }
  }

  async handleViewSubmission(data: AssignmentSubmissionSummaryStudents) {
    const selectedAssignmentData = this.selectedAssignment;
    const iso8601 = selectedAssignmentData.dueTimestamp;
    const dateString = new Date(iso8601).toLocaleDateString();
    const timeString = new Date(iso8601).toLocaleTimeString();
    const { title } = selectedAssignmentData;
    const { code, yearLevel } = selectedAssignmentData.class;
    const { firstName, lastName } = data.student.profile;
    this.show = true;
    this.infos = {
      title: title,
      class: code,
      student: `${firstName} ${lastName}`,
      dates: {
        due: dateString,
        dueBy: timeString,
        submitted: data.submission?.submittedAt
          ? formatDate(data.submission?.submittedAt, "DD/MM/YYYY  hh:mm A")
          : null,
      },
      comment: data.submission?.finalTeacherComments
        ? data.submission?.finalTeacherComments
        : null,
      assignmentStatus: data.submission?.status,
      studentId: data.student.uuid,
      submissionId: data.submission?.uuid,
      learningGoal: data.submission?.studentLearningGoal?.content,
      submission: data.submission,
    };

    await this.handleGetPDFBase64(
      data.submission?.flattenedComponent?.media,
      "submission"
    );
  }

  async handleReturnSubmission(data: AssignmentSubmissionSummaryStudents) {
    //
    try {
      const assignmentId: any = data.submission?.assignmentUuid;
      const submissionid: any = data.submission?.uuid;
      const finalComment: any = data.submission?.finalTeacherComments;
      const response =
        await this.assignmentService.postFlattenAssignmentBySubmissionId(
          assignmentId,
          submissionid
        );

      if (response.data.ok) {
        this.eventBus.emit("SHOW_TOAST", {
          message: "Assignment Submission is being processed.",
          severity: "success",
        });
      }
    } catch (e) {
      //
    }
  }

  async handleReturnFeedbackPerSubmission(
    data: AssignmentSubmissionSummaryStudents
  ) {
    try {
      if (data.student.email) {
        const response = await this.assignmentService.postReturnFeedback(
          this.selectedAssignment.uuid,
          data.submission?.uuid as string
        );
        if (response.data.ok) {
          // this.showMessage("Submission is being processed.", "success");
          this.eventBus.emit("REFRESH_ASSIGNMENT");
          this.eventBus.emit("SHOW_TOAST", {
            message: "Learning goal has been requested.",
            severity: "success",
          });
        }
      } else {
        const studentWithoutEmail = [];
        studentWithoutEmail.push(data.student);
        this.studentWithoutEmail = studentWithoutEmail;
        this.addEmailModalOpen = true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async handleApproveLearningGoal(data: AssignmentSubmissionSummaryStudents) {
    try {
      if (data.submission) {
        const payload = {
          submissionUuid: data.submission.uuid,
          status: "accepted",
        };
        const response: AxiosResponse =
          await this.learningGoalService.putLearningGoal(payload);
        if (response.data.ok) {
          this.eventBus.emit("REFRESH_ASSIGNMENT");

          this.eventBus.emit("SHOW_TOAST", {
            message: "Learning goal has been approved.",
            severity: "success",
          });
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async handleDownloadSubmission(data: AssignmentSubmissionSummaryStudents) {
    let fileType, media;
    console.log("handleDownloadSubmission");
    console.log(data);
    let res;
    let submissionData!: any;
    if (data.submission?.uuid) {
      res = await this.submissionService.getSubmission(data.submission?.uuid);
      submissionData = res.data.data;
      console.log(res.data);
    }

    const selectedAssignment = this.selectedAssignment;
    if (data && submissionData) {
      const studentProfile = data.student.profile;
      const fileName = `${studentProfile.lastName}, ${studentProfile.firstName} - ${selectedAssignment.title} Submission`;
      if (submissionData.flattenedComponent) {
        fileType = submissionData.flattenedComponent.mimeType;
        media = submissionData.flattenedComponent.media;
      } else if (submissionData.component) {
        fileType = submissionData.component.mimeType;
        media = submissionData.component.media;
      }
      if (fileType && media) {
        const response: AxiosResponse =
          await this.mediaService.getSubmissionFile(media);
        const blob = new Blob([response.data], {
          type: fileType,
        });
        const el = document.createElement("a");
        const href = URL.createObjectURL(blob);
        el.setAttribute("download", fileName);
        el.href = href;
        el.setAttribute("target", "_blank");
        el.click();
        URL.revokeObjectURL(href);
      }
    }
  }

  async handleDeleteSubmission() {
    const data = this.selectedSubmission;
    this.isSubmissionDelete = true;

    this.eventBus.emit("SHOW_LOADING", {
      showLoader: true,
      message: "Processing",
    });
    try {
      if (data.submission) {
        const res = await this.assignmentService.deleteSubmission(
          data.submission.uuid
        );
        this.isSubmissionDelete = false;
        this.showDeleteSubmission = false;

        if (res.data.ok) {
          //
          this.eventBus.emit("REFRESH_ASSIGNMENT");

          this.eventBus.emit("SHOW_TOAST", {
            message: "Student submission is deleted.",
            severity: "success",
          });
        }
      }
    } catch (e) {
      //
      this.isSubmissionDelete = false;
      this.showDeleteSubmission = false;
    } finally {
      this.isSubmissionDelete = false;
      this.showDeleteSubmission = false;
    }
  }

  handleAddAssignment() {
    this.$router.push({
      name: "CreateAssignment",
      query: { class: this.classUuid },
    });
  }

  hasCredits(data: AssignmentSubmissionSummaryStudents) {
    const credits = localStorage.getItem("CREDITS_AVAILABLE");
    if (credits) {
      if (Number(credits) === 0 && data?.submission?.status === "unmarked") {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  handleStartMarking(data: AssignmentSubmissionSummaryStudents) {
    if (this.hasCredits(data)) {
      this.$router.push({
        name: "Marking",
        params: { id: data.submission?.uuid },
      });
    } else {
      this.eventBus.emit("ACCOUNT_CREDIT_MODAL", true);
    }
  }

  handleTableBulkAction(action: string) {
    if (action === "Delete Submission") {
      this.bulkDeleteVisible = true;
    } else if (action === "Download Submission") {
      this.handleDownloadSelectedSubmissions();
    }
  }

  async handleDownloadSelectedSubmissions() {
    try {
      this.eventBus.emit("SHOW_LOADING", {
        showLoader: true,
        message: "Preparing to download selected submissions",
      });
      const submissionUuids: string[] = [];

      this.selectedStudents.forEach(
        async (item: AssignmentSubmissionSummaryStudents) => {
          if (item.submission) {
            submissionUuids.push(item.submission.uuid);
          }
        }
      );

      const response: AxiosResponse =
        await this.submissionService.getSelectedSubmissionFiles(
          this.selectedAssignment.uuid,
          submissionUuids.join(",")
        );
      const blob = new Blob([response.data], { type: "application/zip" });
      const el = document.createElement("a");
      const href = URL.createObjectURL(blob);
      el.setAttribute(
        "download",
        `${this.selectedAssignment.title} Submissions`
      );
      el.href = href;
      el.setAttribute("target", "_blank");
      el.click();
      URL.revokeObjectURL(href);
    } catch (err) {
      this.showMessage("There are no flattened documents to download", "error");
    } finally {
      this.eventBus.emit("SHOW_LOADING", {
        showLoader: false,
        message: "",
      });
    }
  }

  handleUploadAssignment(data: AssignmentSubmissionSummaryStudents) {
    this.$store.dispatch(setSelectedStudentSummary, data);
    this.openSubmitStudent = !this.openSubmitStudent;
  }

  handleSubmissionCount() {
    if (this.assignmentSummary) {
      const hasSubmission = this.assignmentSummary.students.filter(
        (student) => student.submission
      );
      const totalSubmissionsDone = hasSubmission.filter(
        (student) =>
          student.submission?.status !== "unassigned" &&
          student.submission?.status !== "assigned"
      ).length;
      this.totalSubmissionsDone = totalSubmissionsDone;
      const percent =
        (this.totalMarkedAssignments / totalSubmissionsDone) * 100;
      this.submissionProgressBar = `${percent}%`;
    }
  }

  toggleBulkUpload() {
    this.eventBus.emit("EVENT_TRIGGER", "CP001");
    this.isBulkUploadOpen = !this.isBulkUploadOpen;
  }

  handleSearch(searchText: string) {
    if (
      this.assignmentSummary &&
      this.assignmentSummary.students &&
      this.assignmentSummary.students.length
    ) {
      const students = structuredClone(this.assignmentSummary.students);
      if (searchText) {
        this.students = structuredClone(
          students.filter((student: AssignmentSubmissionSummaryStudents) => {
            return (
              student.student.profile.firstName
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              student.student.profile.lastName
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              student.submission?.status
                .toLowerCase()
                .includes(searchText.toLowerCase())
            );
          })
        );
      } else {
        this.students = structuredClone(this.assignmentSummary.students);
      }
    }
  }

  async handleMakeAssignmentVisible() {
    try {
      this.loadingUpdateAssignmentVisibility = true;
      const response = await this.assignmentService.updateAssignment(
        this.selectedAssignment.uuid as string,
        { visible: true }
      );
      if (response.data.ok) {
        await this.$store.dispatch(
          setAssignment,
          this.selectedAssignment.uuid as string
        );
        this.visibilityModalUpdateOpen = false;
        this.eventBus.emit("REFRESH_ASSIGNMENT");
        this.showMessage("Assignment is now visible to students", "success");
      }
      this.loadingUpdateAssignmentVisibility = false;
    } catch (err) {
      console.error(err);
    }
  }

  async handleGetPDFBase64(
    media?: string,
    mediaType?: "submission" | "assignment"
  ) {
    if (media) {
      const res = await axios.get(`/media/${media}`);
      const base64 = res.data.data.data;
      if (mediaType === "submission") this.submissionPdfBase64 = base64;
      else this.assignmentPdfBase64 = base64;
    } else {
      setTimeout(() => {
        this.errorMsg = "No file available";
      }, 2000);
    }
  }

  handleAddAssignmentQuery(assignment: AssignmentData) {
    const tabQuery = this.$route.query.tab;
    const tab = tabQuery ? tabQuery.toString() : "";
    if (tab === "assignments" && !this.destroyed) {
      this.$router.push({
        name: "Classes",
        params: { id: assignment.class.uuid },
        query: { tab: "assignments", assignment: assignment.uuid },
      });
    }
  }

  handleResetCriteriaSheetsData() {
    localStorage.removeItem(StorageDataKey.CriteriaSheet);
    localStorage.removeItem(StorageDataKey.CriteriaSheetConfig);
    localStorage.removeItem(StorageDataKey.DeletedLayout);
    localStorage.removeItem(StorageDataKey.RedoLayout);
    localStorage.removeItem(StorageDataKey.PointsMultiplier);
  }

  async handleSelectAssignment(assignment: AssignmentData) {
    this.loading = true;
    await this.$store.dispatch(setAssignmentSubmissionSummary, assignment.uuid);
    const summary = await structuredClone(
      this.$store.state.assignment.assignmentSubmissionSummary
    );
    this.students = summary.students;
    this.assignmentSummary = summary;

    this.handleAddAssignmentQuery(assignment);
    this.resetBase64("assignment");

    this.handleResetCriteriaSheetsData();

    this.selectedAssignment = assignment;
    await this.$store.dispatch(setAssignment, assignment.uuid);

    this.handleHasMarkedStatusCheck();
    this.handleSubmissionCount();

    await this.handleGetPDFBase64(
      this.selectedAssignment.component.media,
      "assignment"
    );
    this.selectedAssignmentFilter = assignment.uuid;
    this.loading = false;
  }

  handleHasMarkedStatusCheck() {
    const markedAssignments = this.assignmentSummary.students.filter(
      (student) =>
        student.submission &&
        (student.submission.status === "marked" ||
          student.submission.status === "learning goal requested" ||
          student.submission.status === "learning goal submitted" ||
          student.submission.status === "completed")
    );
    this.hasMarkedStatus = markedAssignments.length > 0 ? true : false;
    this.totalMarkedAssignments = markedAssignments.length;
  }

  toggleMenu(
    event: Event,
    index: number,
    data: AssignmentSubmissionSummaryStudents
  ) {
    if (
      data.submission &&
      !this.selectedAssignment.visible &&
      data.submission.status !== "assigned"
    ) {
      this.visibilityModalUpdateOpen = true;
    } else {
      const ref = this.$refs["menu-" + index] as IMenuRef | undefined;
      if (ref) {
        ref.toggle(event);
      }
    }
  }

  async handleDelete() {
    this.loadingDeleteStudent = true;
    await this.$store.dispatch(deleteStudent, this.selectedDeleteStudent.uuid);
    this.handleHideModal();
    this.loadingDeleteStudent = false;
  }

  async handleBulkDelete() {
    this.isSubmissionsDelete = true;
    this.eventBus.emit("SHOW_LOADING", {
      showLoader: true,
      message: "Removing selected submissions",
    });
    try {
      for (let i = 0; i < this.selectedStudents.length; i++) {
        const submission = this.selectedStudents[i].submission;
        if (this.selectedStudents.length > 0 && submission) {
          const submissionUuid = submission.uuid;
          const response: AxiosResponse =
            await this.assignmentService.deleteSubmission(submissionUuid);
          if (!response.data.ok) {
            throw new Error();
          }
        }
      }
      this.selectedStudents = [];
      this.selectedAllStudent = false;
      this.bulkDeleteVisible = false;
      this.showMessage("Students' submissions have been removed", "success");
      this.eventBus.emit("REFRESH_ASSIGNMENT");
    } catch (error) {
      console.error(error);
    } finally {
      this.isSubmissionsDelete = false;
      this.eventBus.emit("SHOW_LOADING", {
        showLoader: false,
        message: "",
      });
    }
  }

  handleSort(sort: string) {
    this.students = sortAssignmentTable(sort, this.students);
  }

  handleHideModal() {
    this.visible = false;
  }

  formatDate(date: string) {
    if (!date) return "";
    return DateFormatter(date, "DD/MM/YYYY");
  }

  formatSeconds(submission: any) {
    if (
      submission &&
      Object.keys(submission).includes("markingTime") &&
      submission.markingTime
    ) {
      const s = submission.markingTime.value;
      const date = new Date(s * 1000).toUTCString().match(/(\d\d:\d\d:\d\d)/);
      if (date) {
        return date[0];
      }
    } else {
      return "00:00:00";
    }
  }

  handleSelectAllStudent(allRow: AssignmentSubmissionSummaryStudents[]) {
    const data = structuredClone(allRow);
    const selectedAll = !this.selectedAllStudent;
    if (selectedAll) {
      const students = data.filter(
        (item: AssignmentSubmissionSummaryStudents) =>
          item.submission &&
          this.hasSubmissionStatusArr.includes(item.submission.status)
      );
      this.selectedStudents = students;
      this.selectedStudent = true;
    } else {
      this.selectedStudents = [];
      this.selectedStudent = false;
    }
  }

  handleStudentSelect(row: AssignmentSubmissionSummaryStudents) {
    const selectedStudentsClone = structuredClone(this.selectedStudents);
    const student = selectedStudentsClone.find(
      (student) =>
        row.submission &&
        student.submission &&
        student.submission.uuid === row.submission.uuid
    );
    if (student) {
      const filteredArray = this.selectedStudents.filter(
        (item) =>
          item.submission &&
          row.submission &&
          item.submission.uuid !== row.submission.uuid
      );
      this.selectedStudents = filteredArray;
    } else {
      this.selectedStudents.push(row);
    }
    if (this.selectedStudents.length > 0) {
      this.selectedAllStudent = true;
    } else {
      this.selectedAllStudent = false;
    }
  }

  redirectToLearningGoalsTab() {
    this.eventBus.emit("CHANGE_CLASS_PAGE_TAB", {
      tabIndex: 3,
      assignmentUuid: this.selectedAssignmentFilter,
    });
  }

  isRowChecked(row: AssignmentSubmissionSummaryStudents) {
    const selectedStudentsClone = structuredClone(this.selectedStudents);
    const selected = selectedStudentsClone.find(
      (student) =>
        student.submission &&
        row.submission &&
        student.submission.uuid === row.submission.uuid
    );
    if (selected) {
      return true;
    } else {
      return false;
    }
  }

  handleHover(data: any) {
    this.hoveredIndex = data.index;
  }
  handleUnhover() {
    this.hoveredIndex = -1;
  }

  handleHideSubmissionModal() {
    this.showDeleteSubmission = false;
  }

  private showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style scoped lang="scss">
.bordered {
  border: 1px solid #d9d9d9;
}
.tooltiptext {
  position: absolute;
  padding: 15px 8px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin-left: 2%;
  margin-top: -4%;
  z-index: 5;
}
.actions {
  &-cell {
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-body {
    background-color: #ffc9ad;
  }
}
.next-actions {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.action-icon-container {
  max-height: 24px;
  max-width: 24px;
  margin: 0 auto;
}
.select-all {
  display: flex;
  align-items: center;
}
.checkbox-head {
  background-color: #f2f2f2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #dee2e6;
}

.assignment-custom-cell {
  position: absolute;
  top: 0;
  left: -1px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;

  width: -webkit-calc(100% + 2px);
  width: -moz-calc(100% + 2px);
  width: calc(100% + 2px);
}
</style>
