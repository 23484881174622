import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row mb-2 gap-x-4" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "flex-1" }
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = { class: "flex items-center justify-center px-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.index === 0)
        ? (_openBlock(), _createBlock(_component_AppTypographyText, {
            key: 0,
            label: "First Name",
            variant: "md",
            type: "body",
            class: "mb-2"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_FormField, {
        type: "text",
        modelValue: _ctx.firstName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
        error: _ctx.error && _ctx.error.firstName ? _ctx.error.firstName : '',
        placeholder: "Jessica",
        required: ""
      }, null, 8, ["modelValue", "error"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.index === 0)
        ? (_openBlock(), _createBlock(_component_AppTypographyText, {
            key: 0,
            label: "Last Name",
            variant: "md",
            type: "body",
            class: "mb-2"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_FormField, {
        type: "text",
        modelValue: _ctx.lastName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
        error: _ctx.error && _ctx.error.lastName ? _ctx.error.lastName : '',
        placeholder: "Jones",
        required: ""
      }, null, 8, ["modelValue", "error"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.index === 0)
        ? (_openBlock(), _createBlock(_component_AppTypographyText, {
            key: 0,
            label: "Email",
            variant: "md",
            type: "body",
            class: "mb-2"
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_FormField, {
        type: "text",
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
        error: _ctx.error && _ctx.error.email ? _ctx.error.email : '',
        placeholder: "Jessica@school.com",
        required: ""
      }, null, 8, ["modelValue", "error"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.studentCount === 1)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass([_ctx.index === 0 ? 'translate-y-2/4' : '', "text-lg rounded-full cursor-not-allowed pi pi-times-circle"])
          }, null, 2))
        : (_openBlock(), _createElementBlock("i", {
            key: 1,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleClickRemoveStudent && _ctx.handleClickRemoveStudent(...args))),
            class: _normalizeClass([_ctx.index === 0 ? 'translate-y-2/4' : '', "text-lg rounded-full cursor-pointer pi pi-times-circle"])
          }, null, 2))
    ])
  ]))
}