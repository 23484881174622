import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full px-4 py-7"
}
const _hoisted_2 = {
  key: 0,
  class: "mb-6"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "list-disc pl-[1.5em]" }
const _hoisted_5 = { class: "flex items-center justify-end w-full" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 1,
  class: "w-full px-4 text-center py-7"
}
const _hoisted_8 = {
  key: 0,
  class: "flex flex-col gap-2 mb-5"
}
const _hoisted_9 = { class: "flex items-center justify-end w-full" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_StudentItem = _resolveComponent("StudentItem")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createBlock(_component_ModalUtility, {
    title: "Edit Student Details",
    modelValue: _ctx.modalOpen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalOpen) = $event))
  }, {
    content: _withCtx(() => [
      (_ctx.showResult)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.successStudents.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_AppTypographyText, {
                    label: "Student updates success",
                    variant: "bd",
                    type: "subtitle",
                    class: "mb-2"
                  }),
                  _createVNode(_component_AppTypographyText, {
                    label: `${_ctx.successStudents.length} ${
              _ctx.successStudents.length > 1 ? 'students' : 'student'
            } successfully updated`,
                    variant: "rg",
                    type: "body",
                    class: "mb-2"
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.failedStudents.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_AppTypographyText, {
                    label: "Student updates failed",
                    variant: "bd",
                    type: "subtitle",
                    class: "mb-2"
                  }),
                  _createVNode(_component_AppTypographyText, {
                    label: _ctx.failedGeneralMessage,
                    variant: "rg",
                    type: "body",
                    class: "mb-2"
                  }, null, 8, ["label"]),
                  _createElementVNode("ul", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.failedStudents, (student, i) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: i,
                        class: "text-flohh-text-body"
                      }, _toDisplayString(student.emailAddress) + " (" + _toDisplayString(student.reason) + ") ", 1))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_AppButton, {
                type: "submit",
                onClick: _ctx.handleCloseModal
              }, {
                icon_left: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icon.checkBlack
                  }, null, 8, _hoisted_6)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Finish ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_AppTypographyText, {
              label: "Some of your student accounts don't have an email. Add them below to continue.",
              variant: "bd",
              type: "title",
              class: "mb-7"
            }),
            (_ctx.studentDataPayload && _ctx.studentDataPayload.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.studentDataPayload, (item, index) => {
                    return (_openBlock(), _createBlock(_component_StudentItem, {
                      key: index,
                      index: index,
                      student: item,
                      onOnStudentUpdate: _ctx.handleStudentUpdate,
                      onOnRemoveStudent: _ctx.handleRemoveStudent
                    }, null, 8, ["index", "student", "onOnStudentUpdate", "onOnRemoveStudent"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_AppButton, {
                type: "submit",
                loading: _ctx.isLoading,
                disabled: _ctx.isLoading,
                onClick: _ctx.updateStudentAccounts
              }, {
                icon_left: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icon.checkBlack
                  }, null, 8, _hoisted_10)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Update Student Accounts ")
                ]),
                _: 1
              }, 8, ["loading", "disabled", "onClick"])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}