<template>
  <div
    :class="isStudentView ? 'bg-flohh-secondary-blue' : 'bg-flohh-primary-pink'"
    class="bg-flohh-neutral-1 00 min-h-screen w-screen flex flex-col justify-start items-center bg-fixed bg-[url('@/assets/bg.jpg')] bg-cover h-screen overflow-hidden"
  >
    <div class="relative flex w-full" v-if="!isLoading && !isFullscreen">
      <div
        class="hidden md:flex w-[50%] flex-col items-center justify-center px-[15px] lg:px-[85px] sticky top-0"
      >
        <div>
          <p class="text-[#404040] text-[24px] leading-[1.5] font-bold">
            Simplifying grading and feedback for teachers while empowering
            students to learn and grow from every assignment.
          </p>
        </div>
      </div>
      <div
        class="relative w-full md:w-[50%] bg-white flex flex-col items-center justify-start overflow-auto h-screen"
      >
        <template v-if="view !== '/register/onboarding'">
          <div
            class="hidden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
          >
            <img
              src="@/assets/flohh-logo.svg"
              class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
            />
          </div>
          <div class="hidden">
            <!-- * TEACHER VIEW -->
            <template v-if="isLoginView && !isStudentView">
              <div
                class="hidden h-[164px] w-full flex flex-col justify-center items-center bg-[url('@/assets/pattern.png')] bg-repeat bg-flohh-primary-light-pink bg-[100%_auto] mb-[90px]"
              >
                <h5 class="font-bold text-flohh-h5 color-flohh-neutral-20">
                  Hi Teacher!
                </h5>
                <p class="text-flohh-text-subtitle color-flohh-neutral-20">
                  Need an account?
                  <router-link to="/register">Sign up here</router-link>
                </p>
              </div>
            </template>
            <template v-if="!isLoginView && !isStudentView">
              <div
                class="hidden h-[164px] w-full flex flex-col justify-center items-center bg-[url('@/assets/pattern.png')] bg-repeat bg-flohh-primary-light-pink bg-[100%_auto] mb-[90px]"
              >
                <h5 class="font-bold text-flohh-h5 color-flohh-neutral-20">
                  Hi Teacher!
                </h5>
                <p class="text-flohh-text-subtitle color-flohh-neutral-20">
                  Already have an account?
                  <router-link to="/login">Sign in here</router-link>
                </p>
              </div>
            </template>

            <!-- * STUDENT VIEW -->
            <template v-if="isLoginView && isStudentView">
              <div
                class="h-[164px] w-full flex flex-col justify-center items-center bg-[url('@/assets/pattern.png')] bg-repeat bg-flohh-primary-light-pink bg-[100%_auto] mb-[90px]"
              >
                <h5 class="font-bold text-flohh-h5 color-flohh-neutral-20">
                  Join Your Teacher's Flohh Class
                </h5>
                <p
                  class="max-w-2xl text-center text-flohh-text-subtitle color-flohh-neutral-20"
                >
                  Add your details below to join your teacher's Flohh class so
                  you can receive updates about assignments and get your
                  feedback.
                  <!-- <router-link to="/register">Sign up here</router-link> -->
                </p>
              </div>
            </template>
            <template v-if="!isLoginView && isStudentView">
              <div
                class="h-[164px] w-full flex flex-col justify-center items-center bg-[url('@/assets/pattern.png')] bg-repeat bg-flohh-primary-light-pink bg-[100%_auto] mb-[90px]"
              >
                <h5 class="font-bold text-flohh-h5 color-flohh-neutral-20">
                  Hi Teacher!
                </h5>
                <p class="text-flohh-text-subtitle color-flohh-neutral-20">
                  Already have an account?
                  <router-link to="/login">Sign in here</router-link>
                </p>
              </div>
            </template>
          </div>
        </template>
        <slot></slot>

        <div
          class="w-full flex justify-center items-center mt-6 gap-2 relative mb-[85px] md:mb-[32px] left-0 right-0"
        >
          <a
            href="https://flohh.com.au/"
            target="_blank"
            class="font-medium text-flohh-text-caption"
          >
            Learn More About Flohh
          </a>
          <div class="font-medium text-flohh-text-caption">|</div>
          <a
            href="https://www.flohh.com.au/privacy/"
            target="_blank"
            class="font-medium text-flohh-text-caption"
            >Privacy</a
          >
          <div class="font-medium text-flohh-text-caption">|</div>
          <a
            href="https://www.flohh.com.au/website-terms/"
            target="_blank"
            class="font-medium text-flohh-text-caption"
            >Terms & Conditions
          </a>
        </div>
      </div>
    </div>

    <template v-if="!isLoading && isFullscreen">
      <slot></slot>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import LoginView from "./LoginView.vue";
import RegistrationSelectionView from "./RegistrationSelectionView.vue";

@Component({
  components: {
    LoginView,
    RegistrationSelectionView,
  },
})
export default class AuthenticationView extends Vue {
  //
  isLoginView = true;
  isStudentView = false;

  @Prop({
    type: String,
    required: true,
  })
  public view!: string;

  isFullscreen = false;
  isLoading = true;

  @Watch("view", { immediate: true, deep: true })
  onViewChange(newView: string) {
    this.checkView(newView);
  }

  mounted() {
    this.checkView(this.view);

    if (
      window.location.pathname === "/register/onboarding" ||
      window.location.pathname === "/authenticate/google" ||
      window.location.pathname === "/authenticate/microsoft" ||
      window.location.pathname === "/student/success"
    ) {
      this.isFullscreen = true;
    } else {
      this.isFullscreen = false;
    }

    this.isLoading = false;
  }

  checkView(view: string) {
    if (view.includes("student")) {
      this.isStudentView = true;
    } else {
      this.isLoginView = !view.includes("register");
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
